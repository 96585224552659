import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { DataContextProvider } from './context/DataContext'
import { SessionContextProvider } from './context/SessionContext'

ReactDOM.render(
  <React.StrictMode>
    <SessionContextProvider>
      <DataContextProvider>
        <App />
      </DataContextProvider>
    </SessionContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
