import React, { useState } from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Container from '@material-ui/core/Container'

import routes, { AppRoute } from '../Routes'

import { useSessionContext } from '../context/SessionContext'
import StudentContext from '../context/StudentContext'

import { Student } from '../interfaces/ModuleTypes'
import { isDebug, moduleName } from '../App.config'

import AppMenu from './appmenu/AppMenu'
import Header from './Header'
import TopBar from './TopBar'

const Main: React.FC = () => {
    const classes = useStyles()
    const { sessionState: { user } } = useSessionContext()
    const [currentStudent, setCurrentStudent] = useState({} as Student)
    const studentContextValue = { currentStudent, setCurrentStudent }
    if (!user.userId) return <></>
    return (
        <Router>
            <StudentContext.Provider value={studentContextValue}>
                <div className={classes.root}>
                    <Drawer variant={'permanent'} classes={{ paper: classes.drawerPaper, }}>
                        <Header user={user} moduleName={moduleName} />
                        <Divider />
                        <AppMenu />
                    </Drawer>
                    <main className={classes.content}>
                        <Switch>
                            {
                                routes.map(({ path, Component, exact, debugOnly = false }: AppRoute, key) =>
                                    ((isDebug && debugOnly) || !debugOnly)
                                        ? (
                                            <Route
                                                exact={exact}
                                                path={path}
                                                key={'rt' + key}
                                                render={props => {
                                                    const crumbs = routes
                                                        // Get all routes that contain the current one.
                                                        .filter(({ path }) => props.match.path.includes(path))
                                                        // Swap out any dynamic routes with their param values.                                                        
                                                        .map(({ path, ...rest }) => ({
                                                            path: Object.keys(props.match.params).length
                                                                ? Object.keys(props.match.params).reduce((path, param) => path.replace(`:${param}`, props.match.params[param]!), path)
                                                                : path,
                                                            ...rest
                                                        }))

                                                    //crumbs.map(({ name, path }) => console.log({ name, path }))

                                                    return (
                                                        <>
                                                            <TopBar crumbs={crumbs} />
                                                            <Container className={classes.container}>
                                                                <Component />
                                                            </Container>
                                                        </>
                                                    )
                                                }}
                                            />
                                        )
                                        : <React.Fragment key={'rt' + key} />
                                )}
                        </Switch>
                    </main>
                </div>
            </StudentContext.Provider>

        </Router>
    )
}

export const drawerWidth = 325

export const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'hidden',
        flexGrow: 1,
    },
    drawerPaper: {
        flexShrink: 0,
        whiteSpace: 'nowrap',
        borderRight: '0',
        width: drawerWidth,
        paddingBottom: theme.spacing(4),
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    content: {
        marginTop: '64px',
        marginLeft: drawerWidth,
        overflow: 'auto',
        backgroundColor: theme.palette.background.default
    },
    container: {
        padding: theme.spacing(0, 3, 4, 3),
    }

}))

export default Main
