import axios, { AxiosRequestConfig } from 'axios'
import { xAPIURL, isDebug } from '../App.config'
import { getSessionInfo } from '../utils/Utils'
//import * as LMS from '../LMS'
import { User, SessionInfo, FaultState, FaultAttempt } from '../interfaces/Types'
import { SimutechModule, Student } from '../interfaces/ModuleTypes'


export const xAPIcss = 'padding: 2px 4px; color: #f47e56; font-size: 12px; border-left: 2px solid purple'

enum EndPoints {
    QueryLRSAgent = '/api/xapi/QueryLRSAgent',
    PostLRSStatement = '/api/xapi/PostLRSStatement',
    UpdateLRSAgent = '/api/xapi/UpdateLRSAgent',
    QueryLRSModuleSummary = '/api/xapi/QueryLRSModuleSummary',
    RunLRSQuery = '/api/xapi/RunLRSQuery'
}

export interface LRSStatement {
    user: User
    statement: Statement
}

export interface Statement {
    'registration-id': string
    action: string
    lmsSessionId: string
    duration?: string
    sessionInfo?: SessionInfo
}


export const sendInitialize = async (user: User, registrationID: string) => {
    const sessionInfo = getSessionInfo()
    const statement = createStatement(registrationID, 'initialize', user.lmsSessionId, undefined, sessionInfo)
    const lrsStatement: LRSStatement = { user, statement }
    await sendStatement(lrsStatement)
}

export async function getUserProfile<T>(user: User): Promise<T> {
    return await fetch(xAPIURL + EndPoints.QueryLRSAgent, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    }).then((response) => {
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response.json()
    }).catch(err => {
        console.error('Error retrieving user profile:', err)
        throw new Error(err)
    })
}


export const createStatement = (
    registrationId: string,
    action: string,
    lmsSessionId: string,
    duration?: string,
    sessionInfo?: SessionInfo
): Statement => {
    const statement: Statement = {
        'registration-id': registrationId,
        action: action,
        lmsSessionId,
        ...sessionInfo
    }
    if (duration !== undefined) {
        statement.duration = duration
    }
    return statement
}

const sendBeacon = (statement: LRSStatement): boolean => {
    const url = xAPIURL + EndPoints.PostLRSStatement
    return navigator.sendBeacon(url, JSON.stringify(statement))
}

export const sendTerminateBeacon = (user: User, registrationID: string) => {
    isDebug && console.log('%cSending LRS Terminate beacon...', xAPIcss)
    const sessionTime = 0 //LMS.getSessionAccumulatedTime()
    const statement = createStatement(registrationID, 'terminate', user.lmsSessionId, sessionTime.toString())
    const lrsStatement: LRSStatement = { user, statement }
    sendBeacon(lrsStatement)
}

const sendStatement = async (statement: LRSStatement) => {
    isDebug && console.log('%cSending LRS statement:', xAPIcss, statement)
    return await axios.post(xAPIURL + EndPoints.PostLRSStatement, JSON.stringify(statement), {
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            return response.data
        })
        .catch(err => {
            const error = `Error sending LRS statement: ${err}`
            isDebug && console.error(error)
            throw new Error(error)
        })
}

export const updateProfile = async <T extends unknown>(user: User, profile: Partial<T>): Promise<T> => {
    const updatedProfile = { user, profile }
    isDebug && console.log('%cUpdating LRS Profile...', xAPIcss, updatedProfile)
    return await axios.post(xAPIURL + EndPoints.UpdateLRSAgent, JSON.stringify(updatedProfile))
        .then(response => {
            return response.data
        })
        .catch(err => {
            const error = `Error updating LRS Profile: ${err}`
            isDebug && console.error(error)
            throw new Error(error)
        })
}

// Reporting Endpoints

enum Query {
    GetModuleSummaryByUser = 'GetModuleSummaryByUser',
    GetFaultAttemptSummaryByUser = 'GetFaultAttemptSummaryByUser',
    GetFaultAttemptListByUser = 'GetFaultAttemptListByUser',
    GetFaultAttemptDetails = 'GetFaultAttemptDetailsByFaultAttemptId'
}

type QueryParams = {
    LRSDatabaseName: string
    userId: string
    moduleId: string
}

type QueryParameters = Record<string, string>

interface QueryRequest extends AxiosRequestConfig {
    lmsSessionId: string
    queryName: string
    queryParms?: QueryParameters
}

export type ModuleSummaryResponse = {
    _id: string
    userName: string
    learningLabsTotalTime: number
    learningLabsPercentComplete: number
    troubleshootingSimsTotalTime: number
    troubleshootingSimsTotalFaultsAttempted: number
    troubleshootingSimsTotalFaultsSolved: number
    troubleshootingSimsOverallSafety: number
    troubleshootingSimsOverallAccuracy: number
    troubleshootingSimsOverallEfficiency: number
}

export const getModuleSummaryByUser = async (sessionId: string): Promise<ModuleSummaryResponse[]> => {
    const url = xAPIURL + EndPoints.RunLRSQuery
    const request: QueryRequest = { lmsSessionId: sessionId, queryName: Query.GetModuleSummaryByUser }

    return await axios.post(url, request)
        .then(response => {
            isDebug && console.log(`RunLRSQuery: ${request.queryName}`, response)
            const summary: ModuleSummaryResponse[] = response.data.queryResults as ModuleSummaryResponse[]
            return summary
        })
}

const queryLRS = async (params: QueryParams) => {
    const url = xAPIURL + EndPoints.QueryLRSModuleSummary
    return await axios.post(url, params)
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.error(error)
            throw new Error(`An error occurred while retrieving data: ${error}`)
        })
}

type ModuleIDParam = SimutechModule | '*' | 'user-data'

const getData = async (lrsDBName: string, userId: string, moduleId: ModuleIDParam) =>
    await queryLRS({
        LRSDatabaseName: lrsDBName,
        userId: userId,
        moduleId: moduleId
    })


// User Profiles
export const getAllStudents = async (lrsDBName: string) => {
    isDebug && console.log(`%cAPI: Retrieving all student profiles...`, xAPIcss)
    return await getData(lrsDBName, '*', 'user-data')
}

export const getStudentProfile = async (student: User): Promise<Student[]> => {
    isDebug && console.log(`%cAPI: Retrieving student profile for ${student.userName}...`, xAPIcss)
    return await getData(student.lrsDbName, student.userId, 'user-data')
}

export const getStudentProfileByUserID = async (userId: string, lrsDBName: string): Promise<Student> => {
    isDebug && console.log(`%cAPI: Retrieving student profile for user ID ${userId}...`, xAPIcss)
    return await getData(lrsDBName, userId, 'user-data')
        .then((resp) => {
            if (resp.length < 1) throw new Error(`Seems like there is no student with that identifier.`)
            return resp[0]
        })
}

// Module Profiles

export const getAllModsForAllStudents = async (lrsDBName: string) => {
    isDebug && console.log(`%cAPI: Retrieving all module profiles for all students...`, xAPIcss)
    const profiles = await getData(lrsDBName, '*', '*')
    const pattern = /^((?!user-data).)*$/,
        filteredProfiles = profiles.filter((item: any) => pattern.test(item.profileId))
    return filteredProfiles
}

export const getModuleProfilesForAllStudentsByModule = async (lrsDBName: string, moduleID: SimutechModule) => {
    isDebug && console.log(`%cAPI: Retrieving module profiles for all students in '${moduleID}'...`, xAPIcss)
    return await getData(lrsDBName, '*', moduleID)
}

export const getAllModuleProfilesByStudent = async (student: Student, lrsDBName: string) => {
    isDebug && console.log(`%cAPI: Retrieving all module profiles for student '${student.userName}'...`, xAPIcss)
    const profiles = await getData(lrsDBName, student.userId, '*')
    const pattern = /^((?!user-data).)*$/,
        filteredProfiles = profiles.filter((item: any) => pattern.test(item.profileId))
    return filteredProfiles
}

export const getAllModuleProfilesByUserID = async (studentID: string, lrsDBName: string) => {
    isDebug && console.log(`%cAPI: Retrieving all module profiles for student ID '${studentID}'...`, xAPIcss)
    const profiles = await getData(lrsDBName, studentID, '*')
    const pattern = /^((?!user-data).)*$/,
        filteredProfiles = profiles.filter((item: any) => pattern.test(item.profileId))
    return filteredProfiles
}

export const getModuleProfileByStudent = async (moduleID: SimutechModule, student: User, lrsDBName: string) => {
    isDebug && console.log(`%cAPI: Retrieving '${moduleID}' module profile for student '${student.userName}'...`, xAPIcss)
    return await getData(lrsDBName, student.userId, moduleID)
}

export type FaultAttemptSummary = {
    '_id': string
    'faultDescription': string
    'faultDefectType': string
    'faultDifficultyLevel': string
    'numberOfAttempts': number
    'totalTrainingTime': number
    'averageSafetyScore': number
    'averageAccuracyScore': number
    'averageEfficiencyScore': number
    'averageSkillRating': number
    'firstAttemptTimestamp': string
    'lastAttemptTimestamp': string
}

const runLRSQuery = async <T extends unknown>(queryName: Query, sessionId: string, queryParams?: QueryParameters): Promise<T> => {
    const endPoint = EndPoints.RunLRSQuery
    const url = xAPIURL + endPoint
    const request: QueryRequest = {
        'lmsSessionId': sessionId,
        'queryName': queryName,
        'queryParms': {
            ...queryParams
        }
    }
    isDebug && console.log(`%c${endPoint} query REQUEST: ${request.queryName}`, xAPIcss, request)

    return await axios.post(url, request, { headers: { 'Content-Type': 'application/json' } })
        .then(response => {
            isDebug && console.log(`%c${endPoint} query RESPONSE: ${request.queryName}`, xAPIcss, response)
            return response.data.queryResults as T
        })
        .catch(error => {
            console.error(`An error occurred while retrieving ${endPoint} data:`, error)
            console.error(`The error occurred with query request:`, request)
            throw new Error(`An error occurred while retrieving ${endPoint} data: ${error}`)
        })
}

export const getFaultAttemptSummaryByUser = async (userId: string, sessionId: string, moduleId: SimutechModule): Promise<FaultAttemptSummary[]> => {
    const params: QueryParameters = { 'userId': userId, 'moduleId': moduleId }
    return await runLRSQuery<FaultAttemptSummary[]>(Query.GetFaultAttemptSummaryByUser, sessionId, params)
}

export type FaultAttemptItem = {
    '_id': string
    'faultId': string
    'timestamp': string
    'faultState': FaultState
    'skillRating': null | number
}

export const getFaultAttemptListByUser = async (userId: string, sessionId: string, moduleId: SimutechModule): Promise<FaultAttemptItem[]> => {
    const params: QueryParameters = { 'userId': userId, 'moduleId': moduleId }
    return await runLRSQuery<FaultAttemptItem[]>(Query.GetFaultAttemptListByUser, sessionId, params)
}

export const getFaultAttemptDetails = async (attemptId: string, sessionId: string): Promise<FaultAttempt[]> => {
    const params: QueryParameters = { faultAttemptId: attemptId }
    return await runLRSQuery<FaultAttempt[]>(Query.GetFaultAttemptDetails, sessionId, params)
}