import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import { FaultAttempt } from '../../../interfaces/Types'
import { ScoreDataPoint } from './Styles'

type Props = {
    faultAttempt: FaultAttempt
}

const ScoringResultsView: React.FC<Props> = ({ faultAttempt }: Props) => {
    const { scoringResults } = faultAttempt
    return (
        <div style={{ padding: 13 }}>
            <Grid container direction={'column'} spacing={3}>
                <DataPoint title={`Skill Rating`} result={scoringResults['skill-rating']} />
                <DataPoint title={`Safety`} result={scoringResults['safety-rating']} />
                <DataPoint title={`Accuracy`} result={scoringResults['accuracy-rating']} />
                <DataPoint title={`Efficiency`} result={scoringResults['efficiency-rating']} />
            </Grid>
        </div>
    )
}
export default ScoringResultsView

type DataPointProps = {
    title: string
    result: number
}

const DataPoint: React.FC<DataPointProps> = ({ title, result }: DataPointProps) => {
    return (
        <ScoreDataPoint>
            <Grid container justify={'space-between'} spacing={3}>
                <Grid item>
                    <Typography variant={'h5'}>{title}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant={'h5'}>{`${result}%`}</Typography>
                </Grid>
            </Grid>
        </ScoreDataPoint>
    )
}
