import React, { useState, useEffect } from 'react'
import HashLoader from 'react-spinners/HashLoader'
import styled from 'styled-components'
import { withTheme, useTheme } from '@material-ui/core'

type LoadingIndicatorProps = {
    loading: boolean
    timeout?: number
    text?: string
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ loading, timeout = 250, text = 'Loading...' }: LoadingIndicatorProps) => {
    const [showIndicator, setShowIndicator] = useState(false)
    const theme = useTheme()

    useEffect(() => {
        const timer = setTimeout(() => setShowIndicator(true), timeout)
        return () => clearTimeout(timer)
    })

    if (!showIndicator || !loading) return <></>
    return (
        <Wrapper>
            <HashLoader color={theme.palette.secondary.main} size={40} loading={loading} />
            <LoadingText>{text}</LoadingText>
        </Wrapper>
    )
}

const Wrapper = withTheme(styled(`div`)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${props => props.theme.spacing(5)}px;
`)

const LoadingText = withTheme(styled(`div`)`
    color: ${props => props.theme.palette.primary.main};
    padding: ${props => props.theme.spacing(2)}px;
`)

export default LoadingIndicator