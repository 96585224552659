import React, { useState, useContext, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import * as LRS from '../api/LRS'
import { isDebug } from '../App.config'
import { isSim, isLab, getOverallStudentView } from '../Reporting'

import { useSessionContext } from '../context/SessionContext'
import StudentContext from '../context/StudentContext'

import { LLModuleProfile, TSModuleProfile } from '../interfaces/ModuleTypes'
import { DataTiles } from './dataview/DataTile'


import LoadingIndicator from './LoadingIndicator'
import CriticalError from './CriticalError'
import TileView from './dataview/TileView'
import LearningProgress from './student/LearningProgress'
import SkillRating from './student/SkillRating'

import { PrimaryHeader } from '../styles/Styles'


type IndividualData = {
    labs: LLModuleProfile[]
    sims: TSModuleProfile[]
}

const IndividualData: React.FC = () => {
    const { sessionState: { user } } = useSessionContext()
    const { currentStudent, setCurrentStudent } = useContext(StudentContext)

    const [error, setError] = useState('')
    const [studentModules, setStudentModules] = useState<IndividualData>()
    const [dataTiles, setDataTiles] = useState<DataTiles[]>()

    const history = useHistory()
    const { studentId } = useParams<{ studentId: string }>()

    useEffect(() => {
        if (currentStudent.userId === studentId) return
        isDebug && console.log('Fetch Student effect for studentId :', studentId)
        const fetchData = async () => {
            const student = await LRS.getStudentProfileByUserID(studentId, user.lrsDbName)
            setCurrentStudent(student)
        }
        fetchData()
            .catch(error => setError(error.toString()))

    }, [currentStudent.userId, history, setCurrentStudent, studentId, user.lrsDbName])


    useEffect(() => {
        if (studentModules || currentStudent.userId !== studentId) return
        isDebug && console.log(`Fetch Student Data effect for currentStudentId ${currentStudent.userId}`)
        const fetchData = async () => {

            const profiles = await LRS.getAllModuleProfilesByUserID(currentStudent.userId, user.lrsDbName)
            const simProfiles: TSModuleProfile[] = profiles.filter(isSim)
            const labProfiles: LLModuleProfile[] = profiles.filter(isLab)
            setStudentModules({
                labs: labProfiles,
                sims: simProfiles
            })
        }
        fetchData()
    }, [studentModules, user, studentId, setCurrentStudent, currentStudent])

    useEffect(() => {
        if (dataTiles || currentStudent.userId !== studentId || !studentModules) return
        isDebug && console.log('Set tiles effect.')
        const tiles = getOverallStudentView(currentStudent, studentModules.sims, studentModules.labs)
        setDataTiles(tiles)
    }, [currentStudent, dataTiles, studentId, studentModules])

    if (error) return <CriticalError message={error} />
    if (!dataTiles || !studentModules) return <LoadingIndicator loading={true} text={'Loading profile...'} />
    const { labs, sims } = studentModules
    return (
        <>
            <TileView dataTiles={dataTiles} />
            <PrimaryHeader variant={'h4'} spacing={5}>
                {`Learning Progress`}
            </PrimaryHeader>
            <LearningProgress labs={labs} />
            <PrimaryHeader variant={'h4'}>
                {`Skill Rating`}
            </PrimaryHeader>
            <SkillRating sims={sims} />
        </>
    )
}

export default IndividualData
