import React, { useEffect, useState } from 'react'

import { useDataContext } from '../context/DataContext'
import TileView from './dataview/TileView'
import { getOverallView, getLearningLabsView, getSimulationsView, HomeViews, isLab, isSim } from '../Reporting'
import LoadingIndicator from './LoadingIndicator'
import { isDebug } from '../App.config'
import { PrimaryHeader } from '../styles/Styles'
import { TSModuleProfile, LLModuleProfile } from '../interfaces/ModuleTypes'

const Home: React.FC = () => {
    const { dataState: { allStudents, allProfiles } } = useDataContext()
    const [dataViews, setDataViews] = useState<HomeViews>()

    useEffect(() => {
        if ((!allStudents.length && !allProfiles.length)) return
        const simProfiles: TSModuleProfile[] = allProfiles.filter(isSim)
        const labProfiles: LLModuleProfile[] = allProfiles.filter(isLab)
        isDebug && console.log('Home DataViews effect.')

        const views = {
            overall: getOverallView(allStudents, simProfiles, labProfiles),
            labs: getLearningLabsView(labProfiles),
            sims: getSimulationsView(simProfiles)
        }
        setDataViews(views)

    }, [allProfiles, allStudents])

    if (!dataViews) return <LoadingIndicator loading={true} timeout={25} />

    return (
        <>
            <PrimaryHeader variant={'h4'}>
                {`Overall Data`}
            </PrimaryHeader>
            <TileView dataTiles={dataViews.overall} />
            <TileView title={`Learning Labs Data`} dataTiles={dataViews.labs} />
            <TileView title={`Troubleshooting Simulations Data`} dataTiles={dataViews.sims} />
        </>
    )
}

export default Home
