import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'


import { useSessionContext } from '../../../context/SessionContext'
import StudentContext from '../../../context/StudentContext'

import { SimutechModule } from '../../../interfaces/ModuleTypes'
import { FaultAttempt } from '../../../interfaces/Types'

import * as LRS from '../../../api/LRS'
import { isDebug } from '../../../App.config'
import { getModuleName } from '../../../ModuleNames'

import DataTile, { DataTileType } from '../../dataview/DataTile'
import { PrimaryHeader, SecondaryHeader } from '../../../styles/Styles'
import CriticalError from '../../CriticalError'
import LoadingIndicator from '../../LoadingIndicator'
import FaultDetailsView from './FaultDetailsView'


const FaultDetails = () => {
    const { currentStudent, setCurrentStudent } = useContext(StudentContext)
    const { sessionState: { user } } = useSessionContext()

    const [error, setError] = useState('')
    const [faultAttempt, setFaultAttempt] = useState<FaultAttempt>()

    const { studentId, modId, faultAttemptId } = useParams<{
        studentId: string
        modId: SimutechModule
        faultAttemptId: string
    }>()

    const moduleName = getModuleName(modId)

    useEffect(() => {
        if (currentStudent.userId === studentId) return
        isDebug && console.log('Fetch Student effect for Fault Details studentId :', studentId)
        const fetchData = async () => {
            const student = await LRS.getStudentProfileByUserID(studentId, user.lrsDbName)
            setCurrentStudent(student)
        }
        fetchData()
            .catch(error => setError(error.toString()))
    }, [currentStudent.userId, setCurrentStudent, studentId, user.lrsDbName])

    useEffect(() => {
        if (!currentStudent.userId || faultAttempt) return
        const fetchData = async () => {
            const faultAttemptDetails: FaultAttempt[] = await LRS.getFaultAttemptDetails(faultAttemptId, user.lmsSessionId)
            if (!faultAttemptDetails[0]) throw new Error('Invalid fault attempt')
            setFaultAttempt(faultAttemptDetails[0])
        }
        fetchData()
            .catch(error => setError(error.toString()))
    }, [currentStudent.userId, faultAttempt, faultAttemptId, user.lmsSessionId])

    if (error) return <CriticalError message={error} />
    if (!currentStudent.userId || !faultAttempt) return <LoadingIndicator loading={true} text={'Loading fault details...'} />

    return (
        <>
            <Grid container spacing={4} justify={'flex-start'}>
                <Grid item>
                    <DataTile type={DataTileType.Employee} value={0} text={currentStudent.userName.replace(/,/g, ', ')} />
                </Grid>
            </Grid>

            <PrimaryHeader variant={'h4'}>{moduleName}</PrimaryHeader>
            <SecondaryHeader variant={'h5'}>{`Fault Attempt Details`}</SecondaryHeader>

            <FaultDetailsView {...faultAttempt} />

        </>
    )
}

export default FaultDetails
