import React from 'react'

import { Paper, useTheme, withTheme } from '@material-ui/core'
import * as Icons from '../../styles/Icons'
import VerticalProgressBar from '../VerticalProgressBar'
import moment from 'moment'
import styled from 'styled-components'
import 'styled-components/macro'
import { msToDuration } from '../../Reporting'

export enum DataTileType {
    ActiveUsers = 'Active Users',
    TimeElapsed = 'Time Elapsed',
    Completion = 'Completion',
    FaultsSolved = 'Faults Solved',
    SafetyRate = 'Safety Rate',
    AccuracyRate = 'Accuracy Rate',
    EfficiencyRate = 'Efficiency Rate',
    LastTraining = 'Last Trained',
    Employee = 'Employee',
}

export type DataTiles = {
    type: DataTileType
    value: number
    text?: string
}

type Props = {
    type: DataTileType
    value: number
    text?: string
}

type StyleProps = {
    color: string
    backgroundColor: string
}
/**
 * @description Generates a card-like data tile component 
 * for a Dashboard data grid view.
 *
 * @param {*} { type, value, text }
 * @returns
 */
const DataTile: React.FC<Props> = ({ type, value, text }: Props) => {
    const theme = useTheme()

    const styleProps: StyleProps = {
        color: type === DataTileType.Employee
            ? theme.palette.secondary.contrastText
            : theme.palette.primary.main,
        backgroundColor: type === DataTileType.Employee
            ? theme.palette.secondary.main
            : theme.palette.background.paper
    }

    let rate: string = ''
    const BarGraph = () => <VerticalProgressBar value={value} height={'100%'} />
    const title: DataTileType = type
    let Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | undefined

    ({ Icon, text, value, rate } = getTileInfo(type, Icon, text, value, rate))
    //console.log(`Type: ${type} \n Text: ${text} \n Value: ${value}\n`)
    if (isNaN(value)) return <></>

    return (
        <Tile elevation={0}
            css={`
            background-color: ${styleProps.backgroundColor};
            color: ${styleProps.color};
            padding: ${theme.spacing(2)}px;
        `}>
            {
                type !== DataTileType.Employee
                && <Title>{title}</Title>
            }
            <TileContent>
                <Visual>
                    {
                        Icon
                            ? <Icon height={'100%'} width={'100%'} />
                            : <BarGraph />
                    }
                </Visual>
                <Info>
                    {
                        type !== DataTileType.Employee
                        && (
                            <Value>
                                {
                                    rate !== ''
                                        ? rate
                                        : type === DataTileType.TimeElapsed
                                            ? text :
                                            value.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
                                }
                            </Value>
                        )
                    }
                    {
                        type === DataTileType.TimeElapsed
                            ? <Text>{'HH:MM'}</Text>
                            : text && <Text>{text}</Text>
                    }
                </Info>
            </TileContent>
        </Tile>
    )
}

export default DataTile

const Tile = styled(Paper)`
    min-width: 200px;
    max-width: 300px;
    width: 100%;
    height: 100%;    
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
`

const TileContent = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
`

const Title = withTheme(styled('div')`
    font-family: 'SourceSansPro-SemiBold';
    font-size: 1.25rem;
    margin-bottom: ${props => props.theme.spacing(2)}px;
`)

const Visual = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 60px;
    width: 50px;
`
const Info = withTheme(styled('div')`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-evenly;
    margin-left: ${props => props.theme.spacing(3)}px;

`)

const Text = styled('div')`
    font-family: 'SourceSansPro-Light';
    font-size: 1.125rem;        
`

const Value = styled('div')`
    font-family: 'SourceSansPro-SemiBold';
    font-size: 1.75rem;
`

const getTileInfo = (type: DataTileType, Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | undefined, text: string = '', value: number, rate: string) => {
    let date: Date, day: number, monthYear: string
    switch (type) {
        case DataTileType.ActiveUsers:
            Icon = Icons.ActiveUsers
            text = value === 1 ? 'User' : 'Users'
            break

        case DataTileType.Employee:
            Icon = Icons.Employee
            break

        case DataTileType.FaultsSolved:
            Icon = Icons.FaultsSolved
            text = value === 1 ? 'Fault' : 'Faults'
            break

        case DataTileType.TimeElapsed:
            Icon = Icons.TimeElapsed
            text = msToDuration(value)
            break

        case DataTileType.Completion:
            text = 'Complete'
            rate = Math.round(value) + '%'
            break

        case DataTileType.AccuracyRate:
        case DataTileType.EfficiencyRate:
        case DataTileType.SafetyRate:
            rate = Math.round(value) + '%'
            break

        case DataTileType.LastTraining:
            date = new Date(value)
            day = date.getUTCDate()
            monthYear = moment(date).format('MMM YYYY')

            Icon = Icons.LastTraining
            value = day
            text = monthYear
            break
        default:
            break
    }
    return { Icon, text, value, rate }
}
