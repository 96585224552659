import React, { useState, useEffect } from 'react'
import { Badge, Paper, ButtonGroup } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Alert, AlertTitle } from '@material-ui/lab'
import JSONPretty from 'react-json-pretty'

import { useSessionContext } from '../context/SessionContext'
import * as LRS from '../api/LRS'

import 'react-json-pretty/themes/monikai.css'
import { PrimaryHeader, SecondaryHeader } from '../styles/Styles'

import { isDebug } from '../App.config'
import LoadingIndicator from './LoadingIndicator'

const JSONDump: React.FC = () => {
    const { sessionState: { user } } = useSessionContext()
    const [dataList, setDataList] = useState([])
    const [dataListTitle, setDataListTitle] = useState('')
    const [isStudentsLoading, setStudentsLoading] = useState(false)
    const [isProfilesLoading, setProfilesLoading] = useState(false)

    if (!isDebug) throw new Error('Access denied.')

    useEffect(() => {
        setDataList([])
        if (isStudentsLoading) {
            LRS.getAllStudents(user.lrsDbName)
                .then((response) => {
                    console.log('All Student Profiles', response)
                    setStudentsLoading(false)
                    setDataListTitle('Student Profiles')
                    setDataList(response)
                })
        }

        if (isProfilesLoading) {
            LRS.getAllModsForAllStudents(user.lrsDbName)
                .then((response) => {
                    console.log(response)
                    setProfilesLoading(false)
                    setDataListTitle('Module Profiles for all Students')
                    setDataList(response)
                })
        }
    }, [user, isStudentsLoading, isProfilesLoading])

    return (
        <>
            <Alert severity={'warning'} variant={'outlined'}>
                <AlertTitle>{'Debug Only'}</AlertTitle>
                {`This page can only be viewed in non-production environments.`}
            </Alert>

            <PrimaryHeader variant={'h4'}>
                {`JSON Dump`}
            </PrimaryHeader>
            <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                <Button
                    onClick={!isStudentsLoading ? (event: any) => {
                        event.preventDefault()
                        setStudentsLoading(true)
                    } : undefined}
                    disabled={isStudentsLoading}
                >
                    {isStudentsLoading
                        ? 'Loading...'
                        : 'All Students'
                    }
                </Button>

                <Button
                    onClick={!isProfilesLoading ? (event: any) => {
                        event.preventDefault()
                        setProfilesLoading(true)
                    } : undefined}
                    disabled={isProfilesLoading}
                >
                    {isProfilesLoading
                        ? 'Loading...'
                        : 'All Module Profiles'}
                </Button>
            </ButtonGroup>
            <div>
                <LoadingIndicator loading={isStudentsLoading || isProfilesLoading} text={`Fetching dump of ${isStudentsLoading ? 'students' : 'module profiles'}...`} />
                {dataList.length > 0 && <JSONDumpData data={dataList} title={dataListTitle} />}
            </div>
        </>
    )
}

type JSONDumpDataProps = {
    data: any[]
    title: string
}

const JSONDumpData = ({ data, title }: JSONDumpDataProps) => {

    return (
        <>
            <SecondaryHeader variant={'h5'}>
                <Badge color="primary"
                    badgeContent={data.length}
                    max={999}
                >
                    {title}
                </Badge>
            </SecondaryHeader>
            <Paper elevation={3}>
                <JSONPretty id="profiles-json" data={data} />
            </Paper>
        </>
    )
}
export default JSONDump