import React from 'react'
// import SimutechLogo from '../assets/images/logo.png'

import { User } from '../interfaces/Types'
import { withTheme } from '@material-ui/core'
import styled from 'styled-components'

type Props = {
    user: User
    moduleName: string
}

const Header: React.FC<Props> = ({ user, moduleName }: Props) => {

    return (
        <Wrapper>
            <ModuleName>{moduleName}</ModuleName>
        </Wrapper>
    )
}

const Wrapper = withTheme(styled.div`
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrastText};
    
`)

const ModuleName = withTheme(styled.h1`
    color: ${props => props.theme.palette.secondary.light};
    margin-top: 0;
    margin-bottom: 0;
    padding: ${props => props.theme.spacing(1, 3, 2, 3)};
    font-family: 'SourceSansPro-SemiBold';
    font-weight: normal;
`)

export default Header