import React, { useContext, useReducer } from 'react'
import { Student } from '../interfaces/ModuleTypes'

export type DataContextState = {
    allStudents: Student[]
    allProfiles: any[]
}

export const initialData: DataContextState = {
    allStudents: [],
    allProfiles: [],
}

const DataContext = React.createContext<{
    dataState: DataContextState
    dataDispatch: React.Dispatch<DataAction>
}>({ dataState: initialData, dataDispatch: () => null })

enum DataActionType {
    SET_ALL_STUDENTS,
    SET_ALL_PROFILES,
}

export type DataAction =
    {
        type: DataActionType.SET_ALL_STUDENTS
        payload: Student[]
    } |
    {
        type: DataActionType.SET_ALL_PROFILES
        payload: any[]
    }


const dataReducer = (state: DataContextState, action: DataAction) => {
    switch (action.type) {
        case DataActionType.SET_ALL_STUDENTS:
            return { ...state, allStudents: action.payload } as DataContextState
        case DataActionType.SET_ALL_PROFILES:
            return { ...state, allProfiles: action.payload } as DataContextState
        default:
            return state
    }
}

const DataContextProvider: React.FC<{ children: any }> = ({ children }: { children: any }) => {
    const [state, dispatch] = useReducer(dataReducer, initialData)
    const contextValue = { dataState: state, dataDispatch: dispatch }
    return (
        <DataContext.Provider value={contextValue}>
            {children}
        </DataContext.Provider>
    )
}

const useDataContext = () => {
    const context = useContext(DataContext)
    if (!context) {
        throw new Error(
            'useDataContext must be used within a DataContextProvider. Wrap a parent component in <DataContextProvider> to fix this error.'
        )
    }
    return context
}

export { useDataContext, DataContextProvider, DataActionType }