import React from 'react'
import { Typography } from '@material-ui/core'
import { FaultAttempt, FaultState } from '../../../interfaces/Types'
import useFaultAttemptStyles from './Styles'
import { getTotalFaultTime } from '../../../Reporting'

type Props = {
    faultAttempt: FaultAttempt
}

const KeyIndicators: React.FC<Props> = ({ faultAttempt }: Props) => {
    const styles = useFaultAttemptStyles()
    const { performanceMetrics, scoringResults, parValuesMetrics } = faultAttempt
    const metrics = performanceMetrics

    const totalTime = getTotalFaultTime(metrics)

    const meterReadings = metrics['voltmeter-readings'] + metrics['ohmmeter-readings'] + metrics['ammeter-readings']
    const exitByZapPersonalInjuryError = metrics['exit-by-zap-personal-injury-error']
    const stopLiveCircuitErrorOccurrences = metrics['stop-live-circuit-error-occurrences']
    const hintsTaken = metrics['hints-taken']

    const faultFixed = faultAttempt.faultState === FaultState.Repaired
    const faultOperational = !metrics['exit-without-circuit-being-operational']
    const circuitLeftNotOperational = faultFixed && !faultOperational

    const allowedReplacementPenaltyScore = scoringResults['allowed-replacement-penalty-score']
    const allowedRepairsPenaltyScore = scoringResults['allowed-repairs-penalty-score']

    const componentsReplaced = metrics['replaced-components']
    const repairs = metrics['repairs']

    const numberUnnecessaryReplacedComponents = parValuesMetrics && faultFixed && faultOperational
        ? componentsReplaced.filter(replacedItem => !parValuesMetrics['allowed-replaced-components']
            .some(allowed => replacedItem.Name === allowed)).length
        : 0

    const numberUnnecessaryRepairedComponents = parValuesMetrics && faultFixed && faultOperational
        ? repairs.filter(repairedItem => !parValuesMetrics['allowed-repairs']
            .some(allowed => repairedItem.Name === allowed)).length
        : 0

    return (
        <>
            <Typography variant={'h6'} className={styles.heading} gutterBottom={true}>{'Key Indicators'}</Typography>

            <Typography className={circuitLeftNotOperational ? styles.warning : ''}>
                {`${circuitLeftNotOperational ? 1 : 0} - System Left Non-Operational`}
            </Typography>
            <Typography className={exitByZapPersonalInjuryError ? styles.warning : ''}>
                {`${exitByZapPersonalInjuryError ? 1 : 0} - Personal Injury Errors`}
            </Typography>
            <Typography className={allowedReplacementPenaltyScore < 0 ? styles.warning : ''}>
                {`${numberUnnecessaryReplacedComponents} - Components Unnecessarily Replaced`}
            </Typography>
            <Typography className={allowedRepairsPenaltyScore < 0 ? styles.warning : ''}>
                {`${numberUnnecessaryRepairedComponents} - Unnecessary Repairs Made`}
            </Typography>
            <Typography className={stopLiveCircuitErrorOccurrences > 0 ? styles.warning : ''}>
                {`${stopLiveCircuitErrorOccurrences} - Live Circuit Errors`}
            </Typography>
            <Typography className={scoringResults['seat-time-penalty-score'] < 0 ? styles.warning : ''}>
                {`${totalTime} - Time Taken (minutes)`}
            </Typography>
            <Typography className={hintsTaken > 0 ? styles.warning : ''}>
                {`${hintsTaken} - Hints Taken`}
            </Typography>
            <Typography className={scoringResults['meter-readings-penalty-score'] < 0 ? styles.warning : ''}>
                {`${meterReadings} - Meter Readings`}
            </Typography>

        </>
    )
}

export default KeyIndicators