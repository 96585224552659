import React from 'react'
import HashLoader from 'react-spinners/HashLoader'
import { useTheme, makeStyles, Theme, createStyles } from '@material-ui/core'

type Props = {
    loading: boolean
    text?: string
}

export const LoadingOverlay: React.FC<Props> = ({ loading, text }: Props) => {
    const theme = useTheme()
    const classes = useStyles()

    if (!loading) return <></>
    return (
        <div className={classes.wrapper}>
            <div className={classes.content}>
                <HashLoader
                    color={theme.palette.primary.contrastText}
                    size={100}
                    loading={loading}
                />
                <div className={classes.text}>{text ? text : 'Loading...'}</div>
            </div>
        </div>
    )
}
export default LoadingOverlay

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            boxSizing: 'border-box',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: '100%',
            zIndex: 0,
            margin: 0,
            minHeight: '100vh',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.main,
            border: `2px solid ${theme.palette.secondary.main}`
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            margin: 'auto',
            padding: theme.spacing(2),
            color: theme.palette.secondary.main
        },
        text: {
            fontSize: '1.75rem',
            margin: theme.spacing(3, 0, 3, 0)
        }
    }))