import { isDebug } from '../App.config'
import { useState } from 'react'

export const sapphireConsoleCSS = 'padding: 4px 6px; background-color: #f5f5f5; color: #003462; border-left: 3px solid #f47e56'
export enum StorageType {
    LocalStorage = 'localStorage',
    SessionStorage = 'sessionStorage'
}

export const storageAvailable = (type: StorageType): boolean => {
    try {
        const storage = window[type]
        const x = '__storage_test__'
        storage.setItem(x, x)
        storage.removeItem(x)
        return true
    }
    catch (e) {
        return false
    }
}

export const storeInSession = (key: string, value: string) => {
    if (storageAvailable(StorageType.SessionStorage)) {
        window.sessionStorage.setItem(key, value)
        isDebug && console.log(`%cSession storage successful! (${key}, ${value})`, sapphireConsoleCSS)
    }
    else {
        throw new Error(`Failed to store (${key}, ${value}) in session storage. Error: Session storage is unavailable!`)
    }
}

export const getFromSession = (key: string): string => {
    isDebug && console.log(`%cRetrieving key '${key}' from session...`, sapphireConsoleCSS)

    let value: string = ''
    if (storageAvailable(StorageType.SessionStorage)) {
        if (window.sessionStorage.getItem(key)) {
            value = window.sessionStorage.getItem(key)!
            isDebug && console.log(`%cGet session key '${key}' successful! Value: ${value}`, sapphireConsoleCSS)
        }
    }
    else {
        throw new Error(`%cFailed to get session key '${key}'! Error: Session storage is unavailable!`)
    }
    return value
}


// Hook
export const useLocalStorage = <T extends unknown>(key: string, initialValue: T) => {
    const isServer = typeof window === 'undefined'

    const [storedValue, setStoredValue] = useState(() => {
        if (isServer) return initialValue

        try {
            const item = window.localStorage.getItem(key)
            return item ? JSON.parse(item) : initialValue
        }
        catch (error) {
            console.log(error)
            return initialValue
        }
    })

    const setValue = (value: T) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value
            setStoredValue(valueToStore)
            if (!isServer) {
                window.localStorage.setItem(key, JSON.stringify(valueToStore))
            }
        }
        catch (error) {

            console.log(error)
        }
    }

    return [storedValue, setValue]
}