import { withTheme, makeStyles, Theme } from '@material-ui/core'
import styled from 'styled-components'


export const ScoreDataPoint = withTheme(styled('div')`    
    border: 2px solid ${props => props.theme.palette.secondary.main};
    margin: ${props => props.theme.spacing(1)}px 0;
    padding: ${props => props.theme.spacing(1)}px ${props => props.theme.spacing(3)}px;
`)
export const FaultScore = withTheme(styled('div')`
    border: 2px solid ${props => props.theme.palette.secondary.main};
    margin: 0 0 ${props => props.theme.spacing(3)}px;
    padding: ${props => props.theme.spacing(1)}px ${props => props.theme.spacing(10)}px;    
`)
const useFaultAttemptStyles = makeStyles((theme: Theme) => ({
    container: {
        color: theme.palette.primary.main,
        padding: theme.spacing(5)
    },
    warning: {
        color: theme.palette.secondary.main
    },
    heading: {
        fontFamily: 'SourceSansPro-SemiBold',
        color: theme.palette.primary.main
    },
    faultState: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(2),
        fontFamily: 'SourceSansPro-SemiBold'
    },
    success: {
        color: theme.palette.success.main
    },
    failure: {
        color: theme.palette.error.main
    },
    total: {
        marginTop: theme.spacing(1),
        fontFamily: 'SourceSansPro-SemiBold'
    }
}))

export default useFaultAttemptStyles