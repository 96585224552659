import { TSModuleProfile, LLModuleProfile, Student } from './interfaces/ModuleTypes'
import { DataTiles, DataTileType } from './components/dataview/DataTile'
import { getDistinctCountFromArray, getDistinctObjectsFromArray } from './utils/Utils'
import { getModuleNameFromProfileId } from './ModuleNames'
import moment from 'moment'
import { PerformanceMetrics } from './interfaces/Types'

export type ReportingData = {
    students: Student[]
    labs: LLModuleProfile[]
    sims: TSModuleProfile[]
}

export type HomeViews = {
    overall: DataTiles[]
    labs: DataTiles[]
    sims: DataTiles[]
}
export type LearningLabsViews = {
    overall: DataTiles[]
    groupedModules: GroupedModules[]
}
export type TroubleshootingSimsViews = {
    overall: DataTiles[]
    groupedModules: GroupedModules[]
}

export type GroupedModules = {
    moduleName: string
    profiles: LLModuleProfile[] | TSModuleProfile[]
}

const sim = /^((?!(ll-)|(reporting)).)*$/
const lab = /ll-/

export const isSim = (item: any) => sim.test(item.profileId)
export const isLab = (item: any) => lab.test(item.profileId)

export const sumProfileProp = (array: any[], profileProperty: string): number => array.reduce((prev, cur) => prev + cur.profile[`${profileProperty}`], 0)
export const sumProp = (array: any[], profileProperty: string): number => array.reduce((prev, cur) => prev + cur[`${profileProperty}`], 0)

export const msToDuration = (milliseconds: number | null | undefined): string => {
    if (!milliseconds) return '0:00'
    const duration = moment.duration(milliseconds)
    const minutes = duration.minutes()
    const mins = minutes < 10 ? '0' + minutes : minutes.toString()
    return Math.trunc(duration.asHours()).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + ':' + mins
}

export const getTotalFaultTime = (metrics: PerformanceMetrics): number => {
    const totalReplaceTime = sumProp(metrics['replaced-components'], 'Time')
    return Math.ceil(metrics['seat-time'] + totalReplaceTime)
}

export const getSimulationsView = (sims: TSModuleProfile[]): DataTiles[] => [
    { type: DataTileType.ActiveUsers, value: getDistinctCountFromArray(sims, 'userId') },
    { type: DataTileType.TimeElapsed, value: sumProfileProp(sims, 'module-total-training-time') },
    { type: DataTileType.FaultsSolved, value: sumProfileProp(sims, 'module-number-faults-solved') },
    { type: DataTileType.SafetyRate, value: (sumProfileProp(sims, 'module-safety-score') / sims.length) * 100 },
    { type: DataTileType.AccuracyRate, value: (sumProfileProp(sims, 'module-accuracy-score') / sims.length) * 100 },
    { type: DataTileType.EfficiencyRate, value: (sumProfileProp(sims, 'module-efficiency-score') / sims.length) * 100 },
]


export const getLearningLabsView = (labs: LLModuleProfile[]): DataTiles[] => [
    { type: DataTileType.ActiveUsers, value: getDistinctCountFromArray(labs, 'userId') },
    { type: DataTileType.TimeElapsed, value: sumProfileProp(labs, 'module-total-training-time') },
    { type: DataTileType.Completion, value: (sumProfileProp(labs, 'module-score') / labs.length) },
]

export const getOverallView = (students: Student[], sims: TSModuleProfile[], labs: LLModuleProfile[]): DataTiles[] => [
    { type: DataTileType.ActiveUsers, value: students.length },
    { type: DataTileType.TimeElapsed, value: sumProfileProp(sims, 'module-total-training-time') + sumProfileProp(labs, 'module-total-training-time') },
    { type: DataTileType.FaultsSolved, value: sumProfileProp(sims, 'module-number-faults-solved') },
]


export const getOverallStudentView = (student: Student, sims: TSModuleProfile[], labs: LLModuleProfile[]): DataTiles[] => [
    { type: DataTileType.Employee, value: 0, text: student.userName.replace(/,/g, ', ') },
    { type: DataTileType.TimeElapsed, value: sumProfileProp(sims, 'module-total-training-time') + sumProfileProp(labs, 'module-total-training-time') },
    { type: DataTileType.LastTraining, value: new Date(student.profile['simutech-training-system-last-used-timestamp']).valueOf() },
]

export const getGroupedLabModules = (labProfiles: LLModuleProfile[]): GroupedModules[] => {
    const labProfileIds = getDistinctObjectsFromArray(labProfiles, 'profileId')
    return labProfileIds.map((profId) => {
        return {
            moduleName: getModuleNameFromProfileId(profId),
            profiles: labProfiles.filter((lab) => lab.profileId === profId)
        } as GroupedModules

    }) as GroupedModules[]
}

export const getGroupedSimulationModules = (simProfiles: TSModuleProfile[]): GroupedModules[] => {
    const simProfileIds = getDistinctObjectsFromArray(simProfiles, 'profileId')
    return simProfileIds.map((profId) => {
        return {
            moduleName: getModuleNameFromProfileId(profId),
            profiles: simProfiles.filter((sim) => sim.profileId === profId)
        } as GroupedModules

    }) as GroupedModules[]
}