/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react'
import ListItem from '@material-ui/core/ListItem'
import { NavLink, NavLinkProps } from 'react-router-dom'


interface AppMenuItemComponentProps {
    className?: string
    link?: string | null // because the InferProps props allows null value
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
    children?: React.ReactNode
}

const AppMenuItemComponent: React.FC<AppMenuItemComponentProps> = (props: AppMenuItemComponentProps) => {
    const { className, onClick, link, children } = props

    if (!link || typeof link !== 'string') {
        return (
            <ListItem
                button
                className={className}
                onClick={onClick}
            >{children}
            </ListItem>
        )
    }

    return (
        <ListItem
            button
            className={className}
            component={forwardRef(function CustomNavLink(props: NavLinkProps, ref: any) { return <NavLink {...props} exact={props.to === '/'} innerRef={ref} /> })}
            to={link}
        >
            {children}
        </ListItem>
    )
}

export default AppMenuItemComponent
