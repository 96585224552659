import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme, Paper, TablePagination, Theme, PaperProps } from '@material-ui/core'
import MaterialTable, { Column, MTableHeader } from 'material-table'
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing'

import * as LRS from '../api/LRS'
import { isDebug } from '../App.config'

import { useSessionContext } from '../context/SessionContext'
import StudentContext from '../context/StudentContext'

import { PrimaryHeader } from '../styles/Styles'
import { useLocalStorage } from '../utils/StorageHandler'

import { Student } from '../interfaces/ModuleTypes'
import CriticalError from './CriticalError'
import LoadingIndicator from './LoadingIndicator'
import { msToDuration } from '../Reporting'

const StudentList: React.FC = () => {
    const { sessionState: { user } } = useSessionContext()
    const { currentStudent, setCurrentStudent } = useContext(StudentContext)
    const [tableData, setTableData] = useState<LRS.ModuleSummaryResponse[]>([])
    const [error, setError] = useState('')

    const [density, setDensity] = useLocalStorage('tableDensity', 'default')

    const theme = useTheme()
    const history = useHistory()

    const fetchData = useCallback(async () => {
        isDebug && console.log('Fetching data...')
        const data = await LRS.getModuleSummaryByUser(user.lmsSessionId)
        if (data.length) setTableData(data)
    }, [user.lmsSessionId])

    const refreshData = () => {
        isDebug && console.log('Refreshing data...')
        setTableData([])
    }

    useEffect(() => {
        if (tableData.length) return
        fetchData().catch(error => setError(error.toString()))
    }, [tableData, fetchData])

    const clearCurrentStudent = () => setCurrentStudent({} as Student)

    const selectStudent = (event: any, rowData: LRS.ModuleSummaryResponse | undefined) => {
        event.preventDefault()
        if (!rowData) return
        if (currentStudent.userId !== rowData._id) clearCurrentStudent()
        history.push(`/individual-data/${rowData._id}`)
    }

    if (error) return <CriticalError message={`There was a problem loading the list of students. ${error}`} />
    const isLoading = !tableData.length

    return (
        <>
            <PrimaryHeader variant={'h4'}>{'Individual Data'}</PrimaryHeader>
            <MaterialTable
                title={<PrimaryHeader variant={'h4'}>{'Students'}</PrimaryHeader>}
                columns={getColumns(theme)}
                isLoading={isLoading}
                data={tableData}
                onRowClick={(event, rowData) => selectStudent(event, rowData)}
                components={
                    {
                        Container: function CustomContainer(props: PaperProps) { return <Paper elevation={0}>{props.children}</Paper> },
                        Header: function CustomHeader(props) {
                            return (
                                <>
                                    <thead>
                                        <tr style={{
                                            color: theme.palette.secondary.main,
                                            textAlign: 'center',
                                            fontSize: theme.typography.h4.fontSize,
                                            fontFamily: 'SourceSansPro-SemiBold',
                                        }}>
                                            <td />
                                            <td colSpan={2} style={{
                                                padding: theme.spacing(3, 3, 0, 3),

                                            }}>{'Learning Labs'}
                                            </td>
                                            <td colSpan={5} style={{
                                                padding: theme.spacing(3, 3, 0, 3),

                                            }}>{'Troubleshooting Simulations'}
                                            </td>
                                        </tr>
                                    </thead>
                                    <MTableHeader {...props} />
                                </>
                            )
                        },
                        Pagination: function CustomPagination(props) {
                            return (
                                <TablePagination style={{
                                    color: theme.palette.primary.main,
                                }} {...props} />
                            )
                        }
                    }
                }
                options={{
                    loadingType: 'linear',
                    draggable: false,
                    showTitle: false,
                    search: true,
                    searchFieldStyle: {
                        color: theme.palette.primary.main,
                    },
                    searchFieldAlignment: 'left',
                    searchFieldVariant: 'standard',
                    headerStyle: {
                        fontSize: theme.typography.body1.fontSize,
                        color: theme.palette.primary.main,
                        fontFamily: 'SourceSansPro-SemiBold'
                    },
                    padding: density,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 20, 50],
                    rowStyle: {
                        color: theme.palette.primary.main,
                        fontSize: theme.typography.body1.fontSize,
                    },
                    emptyRowsWhenPaging: true,
                    toolbar: true,

                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} of {count}'
                    },
                    toolbar: {
                        searchPlaceholder: 'Search by name',
                        nRowsSelected: '{0} row(s) selected'
                    },
                    header: {
                        actions: 'Actions'
                    },
                    body: {
                        emptyDataSourceMessage: isLoading ? <LoadingIndicator loading={isLoading} text={'Loading students...'} /> : 'No students to display',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
                actions={[
                    {
                        icon: function SpacingIcon() { return <FormatLineSpacingIcon /> },
                        tooltip: `Display density`,
                        isFreeAction: true,
                        onClick: () => setDensity(density === 'default' ? 'dense' : 'default')
                    },
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => refreshData(),
                    }
                ]}
            />
        </>
    )
}

export default StudentList

const getColumns = (theme: Theme): Column<LRS.ModuleSummaryResponse>[] => {
    return [
        {
            field: '_id',
            hidden: true
        },
        {
            title: 'Name',
            field: 'userName',
            defaultSort: 'asc',
            render: (rowData) => rowData.userName?.replace(/,/g, ', ')
        },
        {
            title: 'Time Elapsed',
            field: 'learningLabsTotalTime',
            type: 'numeric',
            render: (rowData) => msToDuration(rowData.learningLabsTotalTime),
            headerStyle: {
                borderLeft: `1px solid ${theme.palette.divider}`,
                whiteSpace: 'nowrap'
            },
            cellStyle: {
                borderLeft: `1px solid ${theme.palette.divider}`,
            },
            searchable: false
        },
        {
            title: 'Complete',
            field: 'learningLabsPercentComplete',
            type: 'numeric',
            render: (rowData) => Math.round(rowData.learningLabsPercentComplete) + '%',
            headerStyle: {
                borderRight: `1px solid ${theme.palette.divider}`,
            },
            cellStyle: {
                borderRight: `1px solid ${theme.palette.divider}`,
            },
            searchable: false
        },
        {
            title: 'Time Elapsed',
            field: 'troubleshootingSimsTotalTime',
            type: 'numeric',
            render: (rowData) => msToDuration(rowData.troubleshootingSimsTotalTime),
            headerStyle: {
                whiteSpace: 'nowrap'
            },
            searchable: false
        },
        {
            title: 'Faults',
            field: 'troubleshootingSimsTotalFaultsSolved',
            type: 'numeric',
            searchable: false
        },
        {
            title: 'Safety',
            field: 'troubleshootingSimsOverallSafety',
            type: 'numeric',
            render: (rowData) => Math.round(rowData.troubleshootingSimsOverallSafety * 100) + '%',
            searchable: false
        },
        {
            title: 'Accuracy',
            field: 'troubleshootingSimsOverallAccuracy',
            type: 'numeric',
            render: (rowData) => Math.round(rowData.troubleshootingSimsOverallAccuracy * 100) + '%',
            searchable: false
        },
        {
            title: 'Efficiency',
            field: 'troubleshootingSimsOverallEfficiency',
            type: 'numeric',
            render: (rowData) => Math.round(rowData.troubleshootingSimsOverallEfficiency * 100) + '%',
            searchable: false
        },
    ]
}

