import React from 'react'
import { withTheme } from '@material-ui/core'
import styled from 'styled-components'

type Props = {
    value: number
    width?: string
    height?: string
}

const VerticalProgressBar: React.FC<Props> = ({ value, width = '40px', height = '100%' }: Props) => {
    const progress = Math.round(Math.max(0, Math.min(100, value)))

    const styleProps: Props = {
        value: progress,
        width: width,
        height: height
    }

    return (
        <Chart {...styleProps}>
            <Bar {...styleProps} />
        </Chart>
    )
}

export default VerticalProgressBar

const Chart = withTheme(styled.div`
            display: grid;
            grid-template-rows: repeat(100, 1fr);
            margin: ${props => props.theme.spacing(1)}px;
            width: ${(styleProps: Props) => styleProps.width};
            height: ${(styleProps: Props) => styleProps.height};
            min-width: 10px;
            min-height: 10px;
            background: #FDDFD2;
`)

const Bar = withTheme(styled.div`
            position: relative;
            grid-row-start: ${(styleProps: Props) => 101 - styleProps.value};
            grid-row-end: 101;
            background: ${props => props.theme.palette.secondary.main}
`)
