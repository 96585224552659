import React from 'react'
import { PrimaryHeader } from '../styles/Styles'

const DownloadData = () => {
    return (
        <>
            <PrimaryHeader variant={'h4'}>
                {`Download Data`}
            </PrimaryHeader>

        </>
    )
}

export default DownloadData
