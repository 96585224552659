import AppSettings from './App.config.json'
import { AppConfig } from './interfaces/Types'
import { toBoolean } from './utils/Utils'

const appConfig: AppConfig = AppSettings as AppConfig
const environment = process.env.NODE_ENV

export const moduleClass = appConfig.moduleType
export const moduleID = appConfig.moduleID
export const moduleName = appConfig.name

export const debugUsername = appConfig.debugUserName
export const debugUserID = appConfig.debugUserID
export const debugCompanyID = appConfig.debugCompanyID
export const debugCompanyName = appConfig.debugCompanyName
export const debugLRSDBName = appConfig.debugLRSDBName

export const isDebug: boolean = (environment === 'development' || environment === 'test') ? true : toBoolean(appConfig.isDebug)
export const build: string = appConfig.build
export const commitID: string = appConfig.commitID
export const environmentName: string = (environment === 'development') ? environment : appConfig.environmentName

export const xAPIURL: string = appConfig.XAPI[environment].learningEngineURL

const getLMSRequirement = (env: typeof environment): boolean => {
    const setting: string = appConfig.LMS[env].LMSRequired.toLowerCase()
    return toBoolean(setting)
}

export const lmsRequired: boolean = getLMSRequirement(environment)