import { createGlobalStyle } from 'styled-components'
import { SimutechColors } from './Theme'

const iconPath = 'assets/fonts/' // These assets must be in /public folder.

export const GlobalStyle = createGlobalStyle`

html {
  min-height: 100%;
  --simutech-blue: ${SimutechColors.Primary};
  --simutech-orange: ${SimutechColors.Secondary};    
}

@font-face {
  font-family: "SourceSansPro-Regular";
  src: url("${iconPath}SourceSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SourceSansPro-Bold";
  src: url("${iconPath}SourceSansPro-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SourceSansPro-SemiBold";
  src: url("${iconPath}SourceSansPro-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SourceSansPro-Light";
  src: url("${iconPath}SourceSansPro-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansPro-Bold";
  src: url("${iconPath}SourceSansPro-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansPro-SemiBold";
  src: url("${iconPath}SourceSansPro-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansPro-Light";
  src: url("${iconPath}SourceSansPro-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  height: 100%;
}

`