import axios from 'axios'
import { isDebug, xAPIURL } from '../App.config'

const url = xAPIURL + 'api/ccapi/'

enum EndPoints {
    QueryCompanyName = 'QueryCompanyName',
    GetStudentInfo = 'GetStudentInfo',
    GetLMSData = 'GetLMSData',
    SetLMSData = 'SetLMSData',
}

export type LMSInfo = {
    sessionId: string
    courseTrackerId: number
}

type LMSUser = {
    id: number
    firstName: string
    lastName: string
    langKey: string
    userTenant: {
        clientName: string
        clientId: string
        lrsCompanyId: string
        environment: string
        lrsDbName: string
    }
}

export const fetchStudentInfo = async (lmsSessionId: string): Promise<LMSUser> => {
    const getUrl = url + EndPoints.GetStudentInfo + '?sid=' + lmsSessionId
    isDebug && console.log('Fetching user info from:', getUrl)
    return await axios.get<LMSUser>(getUrl)
        .then(response => {
            isDebug && console.log(response)
            return response.data
        })
}