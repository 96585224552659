import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Component, PerformanceMetrics } from '../../../interfaces/Types'
import { sumProp, getTotalFaultTime } from '../../../Reporting'
import useStyles from './Styles'

const Expenditures: React.FC<PerformanceMetrics> = (performanceMetrics: PerformanceMetrics) => {
    const styles = useStyles()

    const totalTime = getTotalFaultTime(performanceMetrics)
    const hourlyCost = (totalTime * 100 / 60)
    const replacedParts: Component[] = performanceMetrics['replaced-components']
    const partsCost = replacedParts.length ? sumProp(replacedParts, 'Cost') : 0

    return (
        <>
            <Typography variant={'h6'} className={styles.heading} gutterBottom={true}>{'Expenditures'}</Typography>
            <div style={{ padding: 13 }}>
                <Grid container direction={'column'} spacing={3}>
                    <Grid container justify={'space-between'} spacing={3}>
                        <Grid item>
                            <Typography>{`${totalTime} minute${totalTime === 1 ? '' : 's'} at $100/hr.`}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{`$${hourlyCost.toFixed(2)}`}</Typography>
                        </Grid>
                    </Grid>
                    {
                        performanceMetrics['replaced-components'].map((component: Component, key: number) => {
                            return (
                                <Grid container justify={'space-between'} key={'cei' + key} spacing={3}>
                                    <Grid item>
                                        <Typography>{`1 - ${component.PartType} (${component.Name})`}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>{`$${component.Cost.toFixed(2)}`}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    <Grid container justify={'space-between'} spacing={3}>
                        <Grid item>
                            <Typography className={styles.total}>{`TOTAL`}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={styles.total}>{`$${(partsCost + hourlyCost).toFixed(2)}`}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Expenditures