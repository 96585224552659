import { ModuleClass, SimutechModule } from './ModuleTypes'

export interface AppConfig {
    moduleType: ModuleClass
    moduleID: SimutechModule
    name: string
    build: string
    commitID: string
    environmentName: string
    isDebug: string
    debugUserName: string
    debugUserID: string
    debugCompanyID: string
    debugCompanyName: string
    debugLRSDBName: string
    LMS: {
        development: {
            LMSRequired: string
        }
        production: {
            LMSRequired: string
        }
        test: {
            LMSRequired: string
        }
    }
    XAPI: {
        development: {
            learningEngineURL: string
        }
        production: {
            learningEngineURL: string
        }
        test: {
            learningEngineURL: string
        }
    }
}

export interface User {
    userId: string
    userName: string
    companyId: string
    companyName: string
    clientId: string
    moduleId: SimutechModule | '*'
    lmsSessionId: string
    lrsDbName: string
    courseTrackerId: number
}

export enum SafetyException {
    Zap = 'Zap',
    Stop = 'Stop',
    Caution = 'Caution',
    SafetyTip = 'SafetyTip',
    MeterTip = 'MeterTip',
    ScrewdriverTip = 'ScrewdriverTip',
    Warning = 'Warning'
}

export enum LMSLessonStatus {
    Passed = '1',
    Completed = '2',
    Failed = '3',
    Incomplete = '4',
    Browsed = '5',
    NotAttempted = '6'
}

export interface SessionInfo {
    environment: string
    'build-number': string
    'commit-number': string
    'user-agent': string
    browser: string
    'browser-version': string
    os: string
    version: string
    'screen-size': string
    'browser-window-size': string

}

export type MasteryLevelName = 'Basic' | 'Intermediate' | 'Advanced' | 'Genius' | 'Master'

export enum FaultState {
    Repaired = 'Repaired',
    NotRepaired = 'NotRepaired',
    Abandoned = 'Abandoned'
}

export interface Component {
    Name: string
    PartType: string
    Cost: number
    Time: number
}

export enum SkillLevel {
    'Basic' = 1,
    'Intermediate',
    'Advanced',
    'Genius',
    'Master'
}

export type FaultAttempt = {
    _id: string
    faultId: string
    timestamp: string
    faultState: FaultState
    scoringResults: ScoringResults
    performanceMetrics: PerformanceMetrics
    parValuesMetrics?: {
        'allowed-replaced-components': string[]
        'allowed-repairs': string[]
    }
}

export type ScoringResults = {
    'overall-score': number
    'skill-rating': number
    'safety-rating': number
    'accuracy-rating': number
    'efficiency-rating': number
    'seat-time-bonus-score': number
    'seat-time-penalty-score': number
    'meter-readings-penalty-score': number
    'enclosure-penalty-score': number
    'inspection-penalty-score': number
    'bench-test-penalty-score': number
    'meter-tip-penalty-score': number
    'hint-penalty-score': number
    'lockouts-penalty-score': number
    'allowed-replacement-penalty-score': number
    'allowed-fuse-penalty-score': number
    'allowed-plc-downloads-penalty-score': number
    'allowed-plc-settings-penalty-score': number
    'allowed-repairs-penalty-score': number
    'allowed-adjustments-penalty-score': number
    'allowed-calibrations-penalty-score': number
    'stop-penalties-score': number
    'caution-penalties-score': number
    'safety-tip-penalties-score': number
    'warning-penalties-score': number
    DefectType: string
    Defect: string
}

export type PerformanceMetrics = {
    RusticiCCAccountID: string
    RusticiCCAccountName: string
    LearningSessionID: string
    faultAttemptID: string
    ModuleIDLong: string
    ModuleIDShort: string
    ModuleFaultID: string
    'seat-time': number
    'voltmeter-readings': number
    'ohmmeter-readings': number
    'ammeter-readings': number
    lockouts: number
    'meter-tips-received': number
    'hints-taken': number
    'replaced-components': Component[]
    repairs: Component[]
    enclosures: string[]
    inspections: number
    'bench-tests': number
    adjustments: any[]
    calibrations: any[]
    'replaced-fuses': number
    'plc-program-downloads': number
    'plc-program-setting-changes': number
    'tightened-connections': string[]
    'stop-live-circuit-error-occurrences': number
    'caution-safe-workplace-practice-occurrences': number
    'safety-tip-messages-received': number
    'warning-messages-received': number
    'exit-without-solving-fault': boolean
    'exit-by-zap-personal-injury-error': boolean
    'attempt-to-leave-fault-with-plc-not-in-run-mode': boolean
    'attempt-to-leave-fault-with-fault-repaired-but-circuit-not-operational': boolean
    'exit-without-circuit-being-operational': boolean
    'exit-by-procedure-failure': boolean
    DefectType: string
    Defect: string
}