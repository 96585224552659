import React, { useContext } from 'react'
import { Breadcrumbs as MUIBreadcrumbs } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Link as RouterLink, useParams } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import { AppRoute, RouteNames } from '../Routes'
import StudentContext from '../context/StudentContext'

type Props = {
    crumbs: AppRoute[]
}

const Breadcrumbs: React.FC<Props> = ({ crumbs }: Props) => {
    const { currentStudent } = useContext(StudentContext)
    const { studentId } = useParams<{ studentId: string }>()

    if (crumbs.length < 1) return null

    return (
        <MUIBreadcrumbs separator={<NavigateNextIcon fontSize={'small'} />} maxItems={4}>
            {crumbs.map(({ path, name }: AppRoute, key) => {
                let text = name as string
                if (studentId && currentStudent.userName && name === RouteNames.StudentProfile) {
                    text = currentStudent.userName.replace(/,/g, ', ')
                }

                return key + 1 === crumbs.length
                    ? (
                        <span key={'bct' + key}>
                            <Typography>{text}</Typography>
                        </span>
                    )
                    : (
                        <Link key={'bct' + key} component={RouterLink} to={path}>
                            <Typography>{text}</Typography>
                        </Link>
                    )
            })}
        </MUIBreadcrumbs>
    )
}

export default Breadcrumbs