import React, { useState, useEffect } from 'react'

import { GroupedModules, getGroupedSimulationModules, getSimulationsView, TroubleshootingSimsViews, isSim } from '../Reporting'
import LoadingIndicator from './LoadingIndicator'
import TileView from './dataview/TileView'
import { TSModuleProfile } from '../interfaces/ModuleTypes'
import { PrimaryHeader } from '../styles/Styles'
import { useDataContext } from '../context/DataContext'

const TroubleshootingSimulations: React.FC = () => {
    const { dataState: { allProfiles } } = useDataContext()
    const [dataViews, setDataViews] = useState<TroubleshootingSimsViews>()

    const simProfiles: TSModuleProfile[] = allProfiles.filter(isSim)

    useEffect(() => {
        if (!simProfiles.length || dataViews) return

        const groupedSims: GroupedModules[] = getGroupedSimulationModules(simProfiles)
        const views = {
            overall: getSimulationsView(simProfiles),
            groupedModules: groupedSims
        }
        setDataViews(views)

    }, [dataViews, simProfiles])


    if (!dataViews) return <LoadingIndicator loading={true} timeout={250} />
    return (
        <>
            <PrimaryHeader variant={'h4'}>
                {`Troubleshooting Simulations Data`}
            </PrimaryHeader>
            <TileView dataTiles={dataViews.overall} />
            {
                dataViews.groupedModules.map((sim, key) => <TileView key={'tstv' + key} title={sim.moduleName} dataTiles={getSimulationsView(sim.profiles as TSModuleProfile[])} />)
            }
        </>
    )
}

export default TroubleshootingSimulations


