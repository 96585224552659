import React from 'react'
import { Grid } from '@material-ui/core'
import DataTile, { DataTiles } from './DataTile'
import { SecondaryHeader } from '../../styles/Styles'

type Props = {
    dataTiles: DataTiles[]
    title?: string
}
const TileView: React.FC<Props> = ({ dataTiles, title }: Props) => {

    if (!dataTiles) return <></>
    return (
        <>
            {
                title && <SecondaryHeader variant={'h5'}>{title}</SecondaryHeader>
            }
            <Grid container spacing={4} justify={'flex-start'}>
                {
                    dataTiles.map(
                        (tile, key) => (
                            <Grid item key={'dtv' + key}>
                                <DataTile type={tile.type} value={tile.value} text={tile.text} />
                            </Grid>
                        )
                    )
                }
            </Grid>
        </>
    )
}

export default TileView
