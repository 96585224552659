import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Container, useTheme } from '@material-ui/core'

type Props = {
    message: string
}

const CriticalError: React.FC<Props> = ({ message }: Props) => {
    const theme = useTheme()
    console.error(message)
    return (
        <Container style={{ padding: theme.spacing(6) }}>
            <Alert severity={'error'} variant={'filled'}>
                <AlertTitle>{'Oops...'}</AlertTitle>
                {message}
            </Alert>
        </Container>
    )
}

export default CriticalError