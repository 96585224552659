import React from 'react'
import { TSModuleProfile } from '../../interfaces/ModuleTypes'
import { getModuleNameFromProfileId, getModuleIdFromProfileId } from '../../ModuleNames'
import { SkillLevel } from '../../interfaces/Types'
import { Column } from 'material-table'
import { useTheme, Theme } from '@material-ui/core'
import TableView from '../dataview/TableView'
import { useParams, useHistory } from 'react-router-dom'
import { msToDuration } from '../../Reporting'

type Props = {
    sims: TSModuleProfile[]
}

type SkillRatingData = {
    moduleId: string
    moduleName: string
    timeElapsed: string
    faults: number
    safety: number
    accuracy: number
    efficiency: number
    level: string
    skillRating: number
    overallScore: number
}

const SkillRating: React.FC<Props> = ({ sims }: Props) => {
    const theme = useTheme()
    const { studentId } = useParams<{ studentId: string }>()
    const history = useHistory()


    const selectModule = (event: any, rowData: SkillRatingData | undefined) => {
        event.preventDefault()
        if (!rowData) return
        history.push(`/individual-data/${studentId}/${rowData.moduleId}`)
    }


    const columns: Array<Column<SkillRatingData>> = getColumns(theme)
    const data: SkillRatingData[] = sims.map((sim) => ({
        moduleId: getModuleIdFromProfileId(sim.profileId),
        moduleName: getModuleNameFromProfileId(sim.profileId),
        timeElapsed: msToDuration(sim.profile['module-total-training-time']),
        faults: sim.profile['module-number-faults-solved'],
        safety: Math.round(sim.profile['module-safety-score'] * 100),
        accuracy: Math.round(sim.profile['module-accuracy-score'] * 100),
        efficiency: Math.round(sim.profile['module-efficiency-score'] * 100),
        level: SkillLevel[sim.profile['module-level-achieved']],
        skillRating: Math.round(sim.profile['module-skill-rating'] * 100),
        overallScore: sim.profile['module-overall-score']
    } as SkillRatingData))

    return (
        <TableView
            title={'Troubleshooting Simulations'}
            data={data}
            columns={columns}
            onRowClick={selectModule}
        />
    )
}
export default SkillRating

const getColumns = (theme: Theme): Column<SkillRatingData>[] => {
    return [
        {
            field: 'moduleId',
            hidden: true,
        },
        {
            field: 'moduleName',
            cellStyle: {
                fontSize: theme.typography.body1.fontSize,
            },
            defaultSort: 'asc',
        },
        {
            title: 'Time Elapsed',
            field: 'timeElapsed',
            type: 'numeric',
        },
        {
            title: 'Faults',
            field: 'faults',
            type: 'numeric',
        },
        {
            title: 'Safety',
            field: 'safety',
            type: 'numeric',
            render: (rowData) => rowData.safety + '%'
        },
        {
            title: 'Accuracy',
            field: 'accuracy',
            type: 'numeric',
            render: (rowData) => rowData.accuracy + '%'
        },
        {
            title: 'Efficiency',
            field: 'efficiency',
            type: 'numeric',
            render: (rowData) => rowData.efficiency + '%'
        },
        {
            title: 'Skill Rating',
            field: 'skillRating',
            type: 'numeric',
            render: (rowData) => rowData.skillRating + '%'
        },
        {
            title: 'Level',
            field: 'level',
            type: 'string',
        },
        {
            title: 'Overall Score',
            field: 'overallScore',
            type: 'numeric',
        }
    ]
}