import { SimutechModule } from './interfaces/ModuleTypes'

export const getModuleIdFromProfileId = (profileId: string): SimutechModule => profileId.substring(profileId.lastIndexOf('/') + 1).toUpperCase() as SimutechModule

export const getModuleNameFromProfileId = (profileId: string) => getModuleName(getModuleIdFromProfileId(profileId))


export const getModuleName = (moduleId: SimutechModule) => {

    switch (moduleId) {
        case SimutechModule.TEC:
            return 'Troubleshooting Electrical Circuits'

        case SimutechModule.TCC:
            return 'Troubleshooting Control Circuits'

        case SimutechModule.TMC:
            return 'Troubleshooting Motor Circuits'

        case SimutechModule.TIC:
        case SimutechModule.TIC1:
        case SimutechModule.TIC2:
            return 'Troubleshooting Industrial Controls'

        case SimutechModule.TIS:
            return 'Troubleshooting Industrial Sensors'

        case SimutechModule.PLC1:
            return 'Troubleshooting PLC 1'
        case SimutechModule.PLC2:
            return 'Troubleshooting PLC 2'
        case SimutechModule.PLC3:
            return 'Troubleshooting PLC 3'

        case SimutechModule.VFD1:
            return 'Troubleshooting Variable Frequency Drives'

        case SimutechModule.LLTF1:
            return 'Troubleshooting Fundamentals'

        case SimutechModule.LLTS1:
            return 'Troubleshooting Safety'

        case SimutechModule.LLTMC:
            return 'Motor Control Components'

        case SimutechModule.LLTCC:
            return 'Control Circuit Components'

        case SimutechModule.REPORTS:
            return 'Simutech Reporting'

        default:
            return moduleId

    }
}
