import { isDebug } from '../App.config'
import * as Storage from './StorageHandler'
import { SessionInfo } from '../interfaces/Types'
import { build, commitID, environmentName } from '../App.config'
import platform from 'platform'

export { Storage }

export const sapphireConsoleCSS = 'padding: 4px 6px; background-color: #f5f5f5; color: #003462; border-left: 3px solid #f47e56'

export const isEmpty = (obj: object): boolean => {
    if (Object.entries(obj).length === 0 && obj.constructor === Object) return true
    return false
}

export const FormatMoney = (num: number): string => {
    const len: number = num.toString().length // Includes decimal
    const pad: number = len > 7 ? 8 : 7
    const money: string = num.toFixed(2).toString().padStart(pad, '0')
    return money
}

const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)

export const getGuid = () => (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase()

export const isString = (value: any) => typeof value === 'string' || value instanceof String

export const toBoolean = (value: string | number | boolean): boolean => {
    if (typeof value === 'string') value = value.toLowerCase()
    return [true, 'true', '1', 1].includes(value)
}

export const openInNewTab = (href: string) => window.open(href, '_blank')

export const isFunction = (functionName: any) => typeof (functionName) === typeof (Function)

export const simulateNetworkRequest = (milliseconds: number) => new Promise(resolve => setTimeout(resolve, milliseconds))

export const preventContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => !isDebug && e.preventDefault()

export const retrieveParamByName = (name: string, url: string) => {
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const getDistinctCountFromArray = (array: any[], property: string) => Array.from(new Set(array.map((x: any) => x[`${property}`]))).length

export const getDistinctObjectsFromArray = (array: any[], property: string) => Array.from(new Set(array.map((x: any) => x[`${property}`])))

export const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min
}

export const getSessionInfo = (): SessionInfo => {
    const navUserAgent = navigator.userAgent
    const screenResolution = `${window.screen.width}x${window.screen.height}`
    const windowDimensions = `${window.innerWidth}x${window.innerHeight}`

    const sessionInfo: SessionInfo = {
        environment: environmentName,
        'build-number': build,
        'commit-number': commitID,
        'user-agent': navUserAgent,
        browser: platform.name!,
        'browser-version': platform.version!,
        os: platform.os!.family!,
        version: platform.os!.version!,
        'screen-size': screenResolution,
        'browser-window-size': windowDimensions
    }

    return sessionInfo
}
