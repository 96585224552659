import React from 'react'
import { LLModuleProfile } from '../../interfaces/ModuleTypes'
import { getModuleNameFromProfileId, getModuleIdFromProfileId } from '../../ModuleNames'

import { Column } from 'material-table'
import { Theme, useTheme } from '@material-ui/core'
import TableView from '../dataview/TableView'
import { msToDuration } from '../../Reporting'

type Props = {
    labs: LLModuleProfile[]
}

type LearningProgressData = {
    moduleId: string
    moduleName: string
    timeElapsed: string
    complete: number
}

const LearningProgress: React.FC<Props> = ({ labs }: Props) => {
    const theme = useTheme()
    const columns: Array<Column<LearningProgressData>> = getColumns(theme)

    const data: LearningProgressData[] = labs.map((lab) => ({
        moduleId: getModuleIdFromProfileId(lab.profileId),
        moduleName: getModuleNameFromProfileId(lab.profileId),
        timeElapsed: msToDuration(lab.profile['module-total-training-time']),
        complete: Math.round(lab.profile['module-score'])
    } as LearningProgressData
    ))

    return <TableView title={'Learning Labs'} data={data} columns={columns} />
}
export default LearningProgress

const getColumns = (theme: Theme): Column<LearningProgressData>[] => {
    return [
        {
            field: 'moduleId',
            hidden: true,
        },
        {
            field: 'moduleName',
            cellStyle: {
                fontSize: theme.typography.body1.fontSize,
            },
            defaultSort: 'asc',
        },
        {
            title: 'Time Elapsed',
            field: 'timeElapsed',
            type: 'numeric',
        },
        {
            title: 'Complete',
            field: 'complete',
            type: 'numeric',
            render: (rowData) => rowData.complete + '%'
        }
    ]
}