import React, { useState, useEffect } from 'react'
import { useDataContext } from '../context/DataContext'

import { LearningLabsViews, getLearningLabsView, GroupedModules, getGroupedLabModules, isLab } from '../Reporting'
import LoadingIndicator from './LoadingIndicator'
import TileView from './dataview/TileView'
import { LLModuleProfile } from '../interfaces/ModuleTypes'
import { PrimaryHeader } from '../styles/Styles'

const LearningLabs: React.FC = () => {
    const { dataState: { allProfiles } } = useDataContext()
    const [dataViews, setDataViews] = useState<LearningLabsViews>()

    const labProfiles: LLModuleProfile[] = allProfiles.filter(isLab)

    useEffect(() => {
        if (!labProfiles.length || dataViews) return

        const groupedLabs: GroupedModules[] = getGroupedLabModules(labProfiles)
        const views = {
            overall: getLearningLabsView(labProfiles),
            groupedModules: groupedLabs
        }
        setDataViews(views)

    }, [dataViews, labProfiles])

    if (!dataViews) return <LoadingIndicator loading={true} timeout={250} />
    return (
        <>
            <PrimaryHeader variant={'h4'}>
                {`Learning Labs Data`}
            </PrimaryHeader>
            <TileView dataTiles={dataViews.overall} />
            {
                dataViews.groupedModules.map((lab, index) => <TileView key={'lltv' + index} title={lab.moduleName} dataTiles={getLearningLabsView(lab.profiles as LLModuleProfile[])} />)
            }
        </>
    )
}

export default LearningLabs

