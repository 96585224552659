import React, { useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { Column } from 'material-table'
import moment from 'moment'

import * as LRS from '../../api/LRS'
import { isDebug } from '../../App.config'
import { getModuleName } from '../../ModuleNames'

import { useSessionContext } from '../../context/SessionContext'
import StudentContext from '../../context/StudentContext'

import { SimutechModule } from '../../interfaces/ModuleTypes'
import { FaultAttemptItem } from '../../api/LRS'
import { FaultState } from '../../interfaces/Types'

import { PrimaryHeader } from '../../styles/Styles'
import DataTile, { DataTileType } from '../dataview/DataTile'
import CriticalError from '../CriticalError'
import LoadingIndicator from '../LoadingIndicator'
import TableView from '../dataview/TableView'

const FaultHistory: React.FC = () => {
    const { currentStudent, setCurrentStudent } = useContext(StudentContext)
    const { sessionState: { user } } = useSessionContext()
    const history = useHistory()

    const [error, setError] = useState('')
    const [tableData, setTableData] = useState<FaultAttemptItem[]>()

    const { studentId, modId } = useParams<{ studentId: string; modId: SimutechModule }>()
    const moduleName = getModuleName(modId)

    useEffect(() => {
        if (currentStudent.userId === studentId) return
        isDebug && console.log('Fetch Student effect for Fault History studentId :', studentId)
        const fetchData = async () => {
            const student = await LRS.getStudentProfileByUserID(studentId, user.lrsDbName)
            setCurrentStudent(student)
        }
        fetchData()
            .catch(error => setError(error.toString()))

    }, [currentStudent.userId, setCurrentStudent, studentId, user.lrsDbName])

    useEffect(() => {
        if (!currentStudent.userId || tableData) return
        const fetchData = async () => {
            const faultAttempts: FaultAttemptItem[] = await LRS.getFaultAttemptListByUser(currentStudent.userId, user.lmsSessionId, modId as SimutechModule)
            setTableData(faultAttempts)
        }
        fetchData()
            .catch(error => setError(error.toString()))

    }, [currentStudent.userId, modId, tableData, user.lmsSessionId])

    const selectAttempt = (event: any, rowData: FaultAttemptItem | undefined) => {
        event.preventDefault()
        if (!rowData) return
        history.push(`/individual-data/${studentId}/${modId}/${rowData._id}`)
    }

    if (error) return <CriticalError message={error} />
    if (!currentStudent.userId) return <LoadingIndicator loading={true} text={'Loading fault history...'} />

    const columns: Column<FaultAttemptItem>[] = getColumns()

    return (
        <div>
            <Grid container spacing={4} justify={'flex-start'}>
                <Grid item>
                    <DataTile type={DataTileType.Employee} value={0} text={currentStudent.userName.replace(/,/g, ', ')} />
                </Grid>
            </Grid>

            <PrimaryHeader variant={'h4'}>{moduleName}</PrimaryHeader>
            <TableView
                title={'Fault Attempt History'}
                isLoading={!tableData}
                data={tableData ?? []}
                columns={columns}
                onRowClick={selectAttempt}
                paging={true}
                search={true}
                searchPlaceholder={'Search by Fault # or State'}
                sorting={true}
            />
        </div>
    )
}

export default FaultHistory

const getColumns = (): Column<FaultAttemptItem>[] => {
    return [
        {
            field: '_id',
            hidden: true,
            searchable: false
        },
        {
            title: 'Date/Time',
            field: 'timestamp',
            type: 'datetime',
            defaultSort: 'desc',
            searchable: false,
            render: (rowData) => moment(rowData.timestamp).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: 'Fault Number',
            field: 'faultId',
            type: 'string',
            searchable: true
        },
        {
            title: 'State',
            field: 'faultState',
            type: 'string',
            render: (rowData) => rowData.faultState === FaultState.NotRepaired ? 'Not Repaired' : rowData.faultState,
            searchable: true
        },
        {
            title: 'Skill Rating',
            field: 'skillRating',
            type: 'numeric',
            render: (rowData) => !rowData.skillRating ? '' : rowData.skillRating + '%',
            searchable: false
        },
    ]
}