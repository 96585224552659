import React from 'react'
import { AppBar, Toolbar, Tooltip, IconButton, useScrollTrigger, makeStyles } from '@material-ui/core'
import ExitIcon from '@material-ui/icons/ExitToAppOutlined'
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined'

import { AppRoute } from '../Routes'

import Breadcrumbs from './Breadcrumbs'
import { drawerWidth } from './Main'
import { useHistory } from 'react-router-dom'
import { useSessionContext } from '../context/SessionContext'

type TopBarProps = {
    crumbs: AppRoute[]
}

const TopBar = (props: TopBarProps) => {
    const classes = useStyles()
    const { sessionState: { user } } = useSessionContext()
    const history = useHistory()

    return (
        <ElevationScroll {...props}>
            <AppBar position={'fixed'} className={classes.appBar}>
                <Toolbar>
                    <div className={classes.crumbs}>
                        <Breadcrumbs crumbs={props.crumbs} />
                    </div>
                    <Tooltip title={user.companyName}>
                        <span className={classes.user}>{user.userName.replace(/,/g, ', ')}</span>
                    </Tooltip>
                    {/* <Tooltip title={'Download All Data'}>
                        <IconButton color={'primary'} onClick={() => history.push('/download')}>
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip> */}
                    <ExitButton />
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    )
}

export default TopBar

// Exit Button

const ExitButton: React.FC = () => {
    const { sessionState: { user, isLMSAvailable } } = useSessionContext()

    if (!isLMSAvailable) return <></>

    const concedeControl = (): void => {
        console.log(`Goodbye ${user.userName}! Closing window...`)
        if (window?.parent) window.parent.postMessage('ConcedeControl', '*')
    }

    const handleExit = (e: React.MouseEvent) => {
        e.preventDefault()
        concedeControl()
    }

    return (
        <Tooltip title={'Exit Reporting Module to LMS.'}>
            <IconButton onClick={handleExit} color={'primary'}>
                <ExitIcon />
            </IconButton>
        </Tooltip>
    )
}

type ESProps = {
    children: React.ReactElement
}

const ElevationScroll = (props: ESProps) => {
    const { children } = props

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    })

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    })
}

const useStyles = makeStyles(theme => ({
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary
    },
    crumbs: {
        flexGrow: 1,
    },
    user: {
        padding: theme.spacing(0, 2)
    },
}))