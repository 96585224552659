
import React from 'react'
import { ReactComponent as Home } from '../assets/icons/home.svg'
import { ReactComponent as ActiveUsers } from '../assets/icons/active-users.svg'
import { ReactComponent as Employee } from '../assets/icons/employee.svg'
import { ReactComponent as FaultData } from '../assets/icons/fault-data.svg'
import { ReactComponent as FaultsSolved } from '../assets/icons/faults-solved.svg'
import { ReactComponent as TimeElapsed } from '../assets/icons/time-elapsed.svg'
import { ReactComponent as IndividualData } from '../assets/icons/individual-data.svg'
import { ReactComponent as LastTraining } from '../assets/icons/last-training.svg'
import { ReactComponent as ModuleData } from '../assets/icons/module-data.svg'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

const iconsPath = '../assets/icons/'

export const activeUsersPath = iconsPath + 'active-users.svg'
export const employeePath = iconsPath + 'employee.svg'
export const faultDataPath = iconsPath + 'fault-data.svg'
export const timeElapsedPath = iconsPath + 'time-elapsed.svg'
export const individualDataPath = iconsPath + 'individual-data.svg'
export const lastTrainingPath = iconsPath + 'last-training.svg'
export const moduleDataPath = iconsPath + 'module-data.svg'


function HomeIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}
            viewBox="0 0 34.37 30"
        >
            <path fill='none' strokeMiterlimit={10} strokeWidth='1.09px' d="M28.71,30H20.55a.73.73,0,0,1-.73-.72V21.05H14.54v8.23a.72.72,0,0,1-.72.72H5.66a.72.72,0,0,1-.72-.72V16.83H.72a.72.72,0,0,1-.67-.46.71.71,0,0,1,.18-.79L16.69.19a.75.75,0,0,1,1,0L34.14,15.58a.75.75,0,0,1,.18.79.73.73,0,0,1-.68.46H29.43V29.28A.72.72,0,0,1,28.71,30Zm-7.44-1.44H28V16.11a.73.73,0,0,1,.73-.72h3.1L17.18,1.71,2.55,15.39H5.66a.72.72,0,0,1,.72.72V28.56H13.1V20.33a.72.72,0,0,1,.72-.72h6.73a.72.72,0,0,1,.72.72Z" />
        </SvgIcon>
    )
}

function ModuleDataIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}
            viewBox="0 0 50.09 38.68"
        >
            <path fill='none' strokeMiterlimit={10} strokeWidth='2.09px' d="M25,34.21V5.63A38.51,38.51,0,0,0,5.62,1.06V29.63S17.16,30.25,25,34.21Z" />
            <path fill='none' strokeMiterlimit={10} strokeWidth='2.09px' d="M25,34.21V5.63A38.57,38.57,0,0,1,44.47,1.06V29.63S32.93,30.25,25,34.21Z" />
            <polyline fill='none' strokeMiterlimit={10} strokeWidth='2.09px' points="5.62 5.63 1.04 5.63 1.04 35.35 20.47 35.35 20.47 37.63 29.62 37.63 29.62 35.35 49.04 35.35 49.04 5.63 44.47 5.63" />
        </SvgIcon>
    )
}

function IndividualDataIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}
            viewBox="0 0 48 56.09"
        >
            <path fill='none' strokeMiterlimit={10} strokeWidth='2.09px' d="M47.54,23.58l-2.19-1.73A21.38,21.38,0,0,0,29.16,1.72V1.21A1.21,1.21,0,0,0,28,0h-7.9a1.21,1.21,0,0,0-1.21,1.21v.51A21.38,21.38,0,0,0,2.65,21.85L.46,23.58a1.18,1.18,0,0,0-.46.94v3.4a1.21,1.21,0,0,0,1.21,1.2h6V39.33a16.76,16.76,0,1,0,33.52,0V29.12h6A1.21,1.21,0,0,0,48,27.92v-3.4A1.18,1.18,0,0,0,47.54,23.58ZM38.35,39.33a14.35,14.35,0,0,1-28.7,0V29.12h28.7Zm7.24-12.62H2.41v-1.6l2.18-1.72a1.21,1.21,0,0,0,.46-.95A19,19,0,0,1,18.84,4.21V6.87a1.21,1.21,0,0,0,2.42,0V2.41h5.49V6.87a1.21,1.21,0,1,0,2.41,0V4.21A19,19,0,0,1,43,22.44a1.21,1.21,0,0,0,.46.95l2.18,1.72Z" />
        </SvgIcon>
    )
}


export {
    HomeIcon,
    Home,
    ActiveUsers,
    Employee,
    FaultData,
    FaultsSolved,
    TimeElapsed,
    IndividualDataIcon,
    IndividualData,
    LastTraining,
    ModuleDataIcon,
    ModuleData
}

