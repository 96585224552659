import { createMuiTheme, Theme, responsiveFontSizes } from '@material-ui/core'

// Default Theme Docs: https://material-ui.com/customization/default-theme/

export enum SimutechColors {
    Primary = '#14345d',
    Secondary = '#f35f20',
    SecondaryLight = '#f47e56',
    ContrastText = '#F1F1F1',
    White = '#F1F1F1'
}

const theme: Theme = createMuiTheme({
    typography: {
        fontFamily: [
            '"SourceSansPro-Regular"',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            '"Fira Sans"',
            '"Droid Sans"',
            'Helvetica Neue',
            'sans-serif'
        ].join(', '),
    },
    palette: {
        type: 'light',
        primary: {
            main: SimutechColors.Primary,
            contrastText: SimutechColors.ContrastText
        },
        secondary: {
            light: SimutechColors.SecondaryLight,
            main: SimutechColors.Secondary,
            contrastText: SimutechColors.ContrastText
        },
        background: {
            default: SimutechColors.White
        }
    },
    overrides: {
        MuiTablePagination: {
            toolbar: {
                //marginTop: '1rem',
                color: SimutechColors.Primary,
                backgroundColor: 'rgba(0, 0, 0, 0.08)'
            }
        },
        MuiTouchRipple: {
            child: {
                color: SimutechColors.Primary,
                backgroundColor: SimutechColors.SecondaryLight
            }
        }
    }
})

const simutechTheme: Theme = responsiveFontSizes(theme)

export default simutechTheme