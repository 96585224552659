import React, { useContext } from 'react'
import { Paper, Grid, Typography } from '@material-ui/core'
import FaultRepairedIcon from '@material-ui/icons/AssignmentTurnedIn'
import FaultNotRepairedIcon from '@material-ui/icons/AssignmentLate'
import moment from 'moment'

import useStyles, { FaultScore } from './Styles'
import { FaultAttempt, FaultState } from '../../../interfaces/Types'

import StudentContext from '../../../context/StudentContext'
import ScoringResultsView from './ScoringResultsView'
import KeyIndicators from './KeyIndicators'
import Expenditures from './Expenditures'

const FaultDetailsView: React.FC<FaultAttempt> = (faultAttempt: FaultAttempt) => {
    const { currentStudent } = useContext(StudentContext)
    const styles = useStyles()

    const { timestamp, faultId, faultState, performanceMetrics, scoringResults } = faultAttempt
    const firstName = currentStudent.userName.substring(currentStudent.userName.lastIndexOf(',') + 1)
    const state = ` The fault was  ${faultState === FaultState.NotRepaired ? 'Not Repaired' : faultState}`

    return (
        <>
            <Paper className={styles.container}>
                <Grid container direction={'column'} spacing={1} alignItems={'center'} justify={'center'} style={{ textAlign: 'center' }}>
                    <Grid item>
                        <Typography>{`Fault ${faultId} started on ${moment(timestamp).format('YYYY-MM-DD HH:mm:ss')}`}</Typography>
                    </Grid>
                    <Grid item>

                        <Typography variant={'h4'} className={styles.faultState + ' ' + (faultState === FaultState.Repaired ? styles.success : styles.failure)}>
                            {
                                faultState === FaultState.Repaired
                                    ? <FaultRepairedIcon fontSize={'large'} />
                                    : <FaultNotRepairedIcon fontSize={'large'} />
                            }
                            {state.toUpperCase()}
                        </Typography>

                    </Grid>
                    <Grid item>
                        <Typography>{`${firstName}'s score on this fault:`}</Typography>
                    </Grid>
                    <Grid item>
                        <FaultScore>
                            <Typography variant={'h3'}>
                                {scoringResults['overall-score']}
                            </Typography>
                        </FaultScore>
                    </Grid>
                </Grid>

                <Grid container justify={'space-between'} spacing={4}>
                    <Grid item>
                        <ScoringResultsView faultAttempt={faultAttempt} />
                    </Grid>
                    <Grid item>
                        <KeyIndicators faultAttempt={faultAttempt} />
                    </Grid>
                    <Grid item>
                        <Expenditures {...performanceMetrics} />
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}
export default FaultDetailsView

