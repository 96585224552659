import React, { useContext, useReducer } from 'react'
import { User } from '../interfaces/Types'
import { UserModuleProfile } from '../interfaces/ModuleTypes'


export type SessionContextState = {
  isLMSAvailable: boolean
  user: User
  userProfile: UserModuleProfile
  errorMessage: string
}

export const initialSession: SessionContextState = {
  isLMSAvailable: false,
  user: {} as User,
  userProfile: {} as UserModuleProfile,
  errorMessage: ''
}

const SessionContext = React.createContext<{
  sessionState: SessionContextState
  sessionDispatch: React.Dispatch<SessionAction>
}>({ sessionState: initialSession, sessionDispatch: () => null })

enum SessionActionType {
  SET_USER,
  SET_PROFILE,
  SET_ERROR_MSG,
  SET_LMS_AVAILABLE
}

export type SessionAction =
  {
    type: SessionActionType.SET_USER
    payload: User
  } |
  {
    type: SessionActionType.SET_PROFILE
    payload: UserModuleProfile
  } |
  {
    type: SessionActionType.SET_ERROR_MSG
    payload: string
  } |
  {
    type: SessionActionType.SET_LMS_AVAILABLE
    payload: boolean
  }

const sessionReducer = (state: SessionContextState, action: SessionAction) => {
  switch (action.type) {
    case SessionActionType.SET_USER:
      return { ...state, user: action.payload } as SessionContextState
    case SessionActionType.SET_PROFILE:
      return { ...state, userProfile: action.payload } as SessionContextState
    case SessionActionType.SET_ERROR_MSG:
      return { ...state, errorMessage: action.payload } as SessionContextState
    case SessionActionType.SET_LMS_AVAILABLE:
      return { ...state, isLMSAvailable: action.payload } as SessionContextState
    default:
      return state
  }
}

const SessionContextProvider: React.FC<{ children: any }> = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(sessionReducer, initialSession)
  const contextValue = { sessionState: state, sessionDispatch: dispatch }
  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  )
}

const useSessionContext = () => {
  const context = useContext(SessionContext)
  if (!context) {
    throw new Error(
      'useSessionContext must be used within a SessionContextProvider. Wrap a parent component in <SessionContextProvider> to fix this error.'
    )
  }
  return context
}

export { useSessionContext, SessionContextProvider, SessionActionType }