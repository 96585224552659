import { Typography, withTheme } from '@material-ui/core'
import styled from 'styled-components'

export const PrimaryHeader = withTheme(styled(Typography)`
    color: ${props => props.theme.palette.primary.main};
    font-family: 'SourceSansPro-SemiBold';
    margin: ${props => props.theme.spacing(4, 0, 2)};
    :first-of-type:not(:first-child) {
         margin: ${props => props.theme.spacing(4, 0, 2)};
    }
    :first-of-type:first-child{
        margin: ${props => props.theme.spacing(2, 0)};
    }
`)

export const SecondaryHeader = withTheme(styled(Typography)`
    color: ${props => props.theme.palette.secondary.main};
    font-family: 'SourceSansPro-SemiBold';
    margin: ${props => props.theme.spacing(5, 0, 2)}
`)
