import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { List, ListItemIcon, ListItemText, Divider, Collapse } from '@material-ui/core'

import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'

import AppMenuItemComponent from './AppMenuItemComponent'


// React runtime PropTypes
const AppMenuItemPropTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
    Icon: PropTypes.elementType,
    items: PropTypes.array,
}

// TypeScript compile-time props type, infered from propTypes
// https://dev.to/busypeoples/notes-on-typescript-inferring-react-proptypes-1g88
type AppMenuItemPropTypes = PropTypes.InferProps<typeof AppMenuItemPropTypes>
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropTypes, 'items'>

// Improve child items declaration
type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
    items?: AppMenuItemProps[]
}

const AppMenuItem: React.FC<AppMenuItemProps> = props => {
    const { name, link, Icon, items = [] } = props
    const [open, setOpen] = useState(true)
    const classes = useStyles()
    const isExpandable = items && items.length > 0


    function handleClick() {
        setOpen(!open)
    }

    const MenuItemRoot = (
        <AppMenuItemComponent className={classes.menuItem} link={link} onClick={handleClick}>
            {!!Icon && (
                <ListItemIcon className={classes.menuItemIcon}>
                    <Icon />
                </ListItemIcon>
            )}
            <ListItemText
                primary={name}
                inset={!Icon}
            />
            {isExpandable && !open && <IconExpandMore />}
            {isExpandable && open && <IconExpandLess />}
        </AppMenuItemComponent>
    )

    const MenuItemChildren = isExpandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <Divider component={'li'} />
                {items.map((item, key) => (
                    <AppMenuItem {...item} key={'amic' + key} />
                ))}
            </List>
        </Collapse>
    ) : null

    return (
        <>
            {MenuItemRoot}
            {MenuItemChildren}
        </>
    )
}

const useStyles = makeStyles(theme =>
    createStyles({
        menuItem: {
            padding: theme.spacing(1, 3),
            '&:hover': {
                color: theme.palette.secondary.light,
                '& .MuiListItemIcon-root': {
                    stroke: theme.palette.secondary.light
                }
            },
            '&.active': {
                color: theme.palette.primary.main,
                background: '#F1F1F1',
                '& .MuiListItemIcon-root': {
                    stroke: theme.palette.secondary.main
                }
            }
        },
        menuItemIcon: {
            stroke: theme.palette.common.white
        }
    }),
)

AppMenuItem.propTypes = AppMenuItemPropTypes


export default AppMenuItem
