import React, { useEffect, useCallback, useRef, useState } from 'react'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { isDebug, lmsRequired } from './App.config'

import { SessionActionType, useSessionContext } from './context/SessionContext'
import { DataActionType, useDataContext } from './context/DataContext'

import * as LRS from './api/LRS'
import { getUser } from './User'

import { UserModuleProfile, Student } from './interfaces/ModuleTypes'
import { LMSInfo } from './api/ContentController'

import LoadingOverlay from './components/LoadingOverlay'
import CriticalError from './components/CriticalError'
import Main from './components/Main'
import Theme from './styles/Theme'
import { GlobalStyle } from './styles/GlobalStyle'

type LMSInfoEvent = {
    sid: string
    ctid: number
}

const App: React.FC = () => {
    const { sessionState: { user, userProfile, errorMessage }, sessionDispatch } = useSessionContext()
    const { dataState: { allProfiles, allStudents }, dataDispatch } = useDataContext()

    const [lmsInfo, setLMSInfo] = useState<LMSInfo | null>(null)

    const setErrorMsg = useCallback((message: string) => sessionDispatch({ type: SessionActionType.SET_ERROR_MSG, payload: message }), [sessionDispatch])

    const handleUnload = useCallback(() => {
        console.warn('App unload triggered. Exiting app...')
        try {
            user.userId && userProfile['module-registration-id'] && LRS.sendTerminateBeacon(user, userProfile['module-registration-id'])
        }
        catch (error) {
            console.log(error)
        }
    }, [user, userProfile])

    const handleCriticalError = useCallback((error: any) => {
        let errorMsg = 'An error has occurred.  Please contact your Learning Management System administrator. '
        isDebug && (errorMsg += error)
        setErrorMsg(errorMsg)
        handleUnload()
    }, [handleUnload, setErrorMsg])

    const handleCrossOriginMessage = useCallback((event: MessageEvent) => {
        // Must check your origin!
        if (!event.origin.includes('.tpcfusion.com')
            && !event.origin.includes('//localhost')
            && !event.origin.includes('.simutechmultimedia.net'))
            return
        if (!event.data)
            return
        const eventData = event.data as LMSInfoEvent
        if (eventData.sid && !lmsInfo) {
            isDebug && console.log('Cross Origin message received:', event)
            const newLMSInfo: LMSInfo = {
                sessionId: eventData.sid,
                courseTrackerId: eventData.ctid
            }

            if (!newLMSInfo.sessionId) return

            isDebug && console.log('LMS Info received!', newLMSInfo)
            setLMSInfo(newLMSInfo)
            sessionDispatch({ type: SessionActionType.SET_LMS_AVAILABLE, payload: true })
        }
    }, [lmsInfo, sessionDispatch])

    useEffect(() => {
        window.addEventListener('unload', handleUnload)
        window.addEventListener('message', handleCrossOriginMessage, false)
        return () => {
            window.removeEventListener('unload', handleUnload)
            window.removeEventListener('message', handleCrossOriginMessage)
        }
    }, [handleUnload, handleCrossOriginMessage])

    const userFetching = useRef(false)
    useEffect(() => {
        if (userFetching.current) return
        const timer = setTimeout(() => {
            if (!lmsInfo) {
                userFetching.current = true
                if (lmsRequired) {
                    handleCriticalError('LMS could not be found to retrieve user.')
                    return
                }
                const getDefaultUser = async () => {
                    const usr = await getUser()
                    sessionDispatch({ type: SessionActionType.SET_USER, payload: usr })
                    setLMSInfo({
                        sessionId: usr.lmsSessionId,
                        courseTrackerId: usr.courseTrackerId
                    } as LMSInfo)
                }
                getDefaultUser()
                clearTimeout(timer)
            }
        }, 3500)
        return () => clearTimeout(timer)
    }, [handleCriticalError, lmsInfo, sessionDispatch])

    useEffect(() => {
        if (!lmsInfo || user.userId || errorMessage || userFetching.current) return
        userFetching.current = true
        const getUserFromCC = async () => {
            const usr = await getUser(lmsInfo)
            sessionDispatch({ type: SessionActionType.SET_USER, payload: usr })
        }
        getUserFromCC()
            .catch(error => handleCriticalError(error.toString()))
    }, [handleCriticalError, sessionDispatch, user.userId, errorMessage, lmsInfo])

    useEffect(() => {
        if (!user.userId || errorMessage || userProfile['module-registration-id']) return
        isDebug && console.log('Init PROFILE effect.')

        const init = async () => {
            const profile = JSON.parse(await LRS.getUserProfile(user)).profile as UserModuleProfile
            await LRS.sendInitialize(user, profile['module-registration-id'])
            sessionDispatch({ type: SessionActionType.SET_PROFILE, payload: profile })
        }
        init().catch((error) => handleCriticalError(error))

    }, [errorMessage, handleCriticalError, sessionDispatch, user, userProfile])

    useEffect(() => {
        if (!user.lrsDbName || allStudents.length || errorMessage) return
        isDebug && console.log('Data Context All Students effect.')

        const fetchData = async () => {
            const students: Student[] = await LRS.getAllStudents(user.lrsDbName)
            dataDispatch({ type: DataActionType.SET_ALL_STUDENTS, payload: students })
        }
        fetchData().catch((error) => handleCriticalError(error))

    }, [allStudents.length, dataDispatch, errorMessage, handleCriticalError, user.lrsDbName])

    useEffect(() => {
        if (!user.lrsDbName || allProfiles.length || errorMessage) return
        isDebug && console.log('Data Context All Profiles effect.')

        const fetchData = async () => {
            const profiles: any[] = await LRS.getAllModsForAllStudents(user.lrsDbName)
            console.log('profiles', profiles)
            dataDispatch({ type: DataActionType.SET_ALL_PROFILES, payload: profiles })
        }
        fetchData().catch((error) => handleCriticalError(error))

    }, [allProfiles.length, dataDispatch, errorMessage, handleCriticalError, user.lrsDbName])

    if (errorMessage) return <CriticalError message={errorMessage} />
    const isReady = user.userId

    return (
        <ThemeProvider theme={Theme}>
            <CssBaseline />
            <GlobalStyle />
            <LoadingOverlay
                loading={!isReady}
                text={'Loading...'}
            />
            <Main />
        </ThemeProvider>
    )
}

export default App