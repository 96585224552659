import React from 'react'
import List from '@material-ui/core/List'

import { HomeIcon, ModuleDataIcon, IndividualDataIcon } from '../../styles/Icons'
import CodeIcon from '@material-ui/icons/Code'

import AppMenuItem from './AppMenuItem'
import { isDebug } from '../../App.config'
import styled from 'styled-components'

const appMenuItems = [
    {
        name: 'Home',
        link: '/',
        Icon: HomeIcon,
        visible: true
    },
    {
        name: 'Module Data',
        Icon: ModuleDataIcon,
        visible: true,
        items: [
            {
                name: 'Learning Labs',
                link: '/learning-labs',
                visible: true
            },
            {
                name: 'Troubleshooting Simulations',
                link: '/troubleshooting-sims',
                visible: true
            },
        ],
    },
    {
        name: 'Individual Data',
        link: '/individual-data',
        Icon: IndividualDataIcon,
        visible: true
    },
    {
        name: 'JSON Dump',
        link: '/json-dump',
        Icon: CodeIcon,
        visible: isDebug
    },
    // {
    //     name: 'Download Data',
    //     link: '/download',
    //     visible: true,
    // }
]

const AppMenu: React.FC = () => {
    return (
        <FullWidthList component="nav" disablePadding>
            {appMenuItems.map((item, key) => {
                return (
                    item.visible &&
                    <AppMenuItem {...item} key={'ami' + key} />
                )
            })}
        </FullWidthList>
    )
}

export default AppMenu

const FullWidthList = styled(List)({
    width: '100%'
}) as typeof List // so this was weird.