import { isDebug } from './App.config'
import { fetchStudentInfo, LMSInfo } from './api/ContentController'
import { NIL as NIL_UUID } from 'uuid'

import { moduleID, debugUsername, debugUserID, debugCompanyID, debugCompanyName, debugLRSDBName } from './App.config'
import { User } from './interfaces/Types'

export const getUser = async (lmsInfo?: LMSInfo): Promise<User> => {
    console.log('Initializing user...')
    if (lmsInfo) {
        isDebug && console.log('Fetching user info from LMS using:', lmsInfo)
        const userInfo = await fetchStudentInfo(lmsInfo.sessionId)
        isDebug && console.log('LMS USER:', userInfo)
        const user: User = {
            userId: userInfo.id.toString(),
            userName: `${userInfo.lastName}, ${userInfo.firstName}`,
            companyId: userInfo.userTenant.lrsCompanyId,
            companyName: userInfo.userTenant.clientName,
            clientId: userInfo.userTenant.clientId,
            moduleId: moduleID,
            lmsSessionId: lmsInfo.sessionId,
            lrsDbName: userInfo.userTenant.lrsDbName,
            courseTrackerId: lmsInfo.courseTrackerId,
        }

        console.table(user)
        return user
    }

    const user: User = {
        userId: debugUserID,
        userName: debugUsername,
        companyId: debugCompanyID,
        companyName: debugCompanyName,
        clientId: 'NOLMS',
        moduleId: moduleID,
        lmsSessionId: NIL_UUID,
        lrsDbName: debugLRSDBName,
        courseTrackerId: 0
    }
    console.table(user)
    return user
}