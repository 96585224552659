import React from 'react'
import { Paper, PaperProps, useTheme } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import MaterialTable, { Column } from 'material-table'
import { SecondaryHeader } from '../../styles/Styles'
import LoadingIndicator from '../LoadingIndicator'

type Props = {
    title?: string
    isLoading?: boolean
    data: any[]
    columns: Array<Column<any>>
    onRowClick?: Function
    paging?: boolean
    search?: boolean
    searchPlaceholder?: string
    sorting?: boolean
}

const TableView: React.FC<Props> = ({
    title,
    isLoading = false,
    data = [],
    columns,
    onRowClick,
    paging = false,
    search = false,
    searchPlaceholder = 'Search',
    sorting = false
}: Props) => {
    const theme = useTheme()

    if (!data.length && !isLoading) return (

        <Paper variant={'outlined'}>
            <div style={{ padding: theme.spacing(0, 3, 4, 3) }}>
                {title && <SecondaryHeader variant={'h5'} style={{ paddingLeft: theme.spacing(3) }}>{title}</SecondaryHeader>}
                <Alert severity={'warning'} variant={'outlined'}>
                    <AlertTitle>{'No Data'}</AlertTitle>
                    {`No data to display.`}
                </Alert>
            </div>
        </Paper>

    )
    return (

        <MaterialTable
            title={<SecondaryHeader variant={'h5'}>{title}</SecondaryHeader>}
            isLoading={isLoading}
            columns={columns}
            data={data}
            onRowClick={onRowClick ? (event, rowData) => onRowClick(event, rowData) : undefined}
            components={
                {
                    Container: function CustomContainer(props: PaperProps) { return <Paper variant={'outlined'}><div style={{ padding: theme.spacing(0, 3, 4, 3) }}>{props.children}</div></Paper> },
                }
            }
            options={{
                loadingType: 'linear',
                draggable: false,
                showTitle: title ? true : false,
                sorting: sorting,
                search: search,
                searchFieldStyle: {
                    color: theme.palette.primary.main,
                },
                searchFieldAlignment: 'right',

                searchFieldVariant: 'outlined',
                headerStyle: {
                    fontSize: theme.typography.h6.fontSize,
                    color: theme.palette.primary.main,
                    fontFamily: 'SourceSansPro-SemiBold'
                },
                padding: 'dense',
                paging: paging,
                pageSize: 10,
                rowStyle: {
                    color: theme.palette.primary.main,
                    fontSize: theme.typography.body1.fontSize,
                },
                toolbar: true,
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: isLoading ? <LoadingIndicator loading={isLoading} text={'Loading...'} /> : 'No data to display',
                },
                toolbar: {
                    searchPlaceholder: searchPlaceholder
                }

            }}
        />
    )
}
export default TableView
