import React from 'react'

import Home from './components/Home'
import LearningLabs from './components/LearningLabs'
import TroubleshootingSimulations from './components/TroubleshootingSimulations'
import JSONDump from './components/JSONDump'
import IndividualData from './components/IndividualData'
import StudentList from './components/StudentList'
import FaultHistory from './components/student/FaultHistory'
import FaultDetails from './components/student/fault-details/FaultDetails'
import DownloadData from './components/DownloadData'

const PageHome = () => <Home />
const PageLearningLabs = () => <LearningLabs />
const PageTroubleshootingSims = () => <TroubleshootingSimulations />
const PageIndividualData = () => <IndividualData />
const PageStudentList = () => <StudentList />
const PageJSONDump = () => <JSONDump />
const PageFaultHistory = () => <FaultHistory />
const PageFaultDetails = () => <FaultDetails />
const PageDownloadData = () => <DownloadData />

export enum RouteNames {
    Home = 'Home',
    TroubleshootingSims = 'Troubleshooting Simulations',
    LearningLabs = 'Learning Labs',
    IndividualData = 'Individual Data',
    StudentProfile = 'Profile',
    FaultHistory = 'Fault History',
    FaultDetails = 'Fault Attempt Details',
    JSONDump = 'JSON Dump',
    DataDownload = 'Download Data'
}

export type AppRoute = {
    path: string
    name: RouteNames
    Component: React.FC
    exact: boolean
    debugOnly?: boolean
}
// Order matters. Do not change order or Breadcrumbs will not work as intended.
const routes: AppRoute[] = [
    {
        path: '/',
        name: RouteNames.Home,
        Component: PageHome,
        exact: true
    },
    {
        path: '/troubleshooting-sims',
        name: RouteNames.TroubleshootingSims,
        Component: PageTroubleshootingSims,
        exact: false
    },
    {
        path: '/learning-labs',
        name: RouteNames.LearningLabs,
        Component: PageLearningLabs,
        exact: false
    },
    {
        path: '/individual-data',
        name: RouteNames.IndividualData,
        Component: PageStudentList,
        exact: true
    },
    {
        path: '/individual-data/:studentId',
        name: RouteNames.StudentProfile,
        Component: PageIndividualData,
        exact: true
    },
    {
        path: '/individual-data/:studentId/:modId',
        name: RouteNames.FaultHistory,
        Component: PageFaultHistory,
        exact: true
    },
    {
        path: '/individual-data/:studentId/:modId/:faultAttemptId',
        name: RouteNames.FaultDetails,
        Component: PageFaultDetails,
        exact: true
    },
    {
        path: '/json-dump',
        name: RouteNames.JSONDump,
        Component: PageJSONDump,
        exact: false,
        debugOnly: true
    },
    {
        path: '/download',
        name: RouteNames.DataDownload,
        Component: PageDownloadData,
        exact: false,
    }

]

export default routes